import type { JobObservable } from '@fds/wm-typescript-mdg2-client';
import { defer, from, repeat, timer } from 'rxjs';
import type { Observable } from 'rxjs';
import type {
  FactsetHighLevelRequestResult,
  FactsetObserverConfig,
  FactsetRequestResult
} from './factset.types';

export class FactsetClient {
  public static observeEndpoint<TRequest extends Record<string, unknown>, TResponse>(
    config: FactsetObserverConfig<TRequest>
  ): JobObservable<FactsetRequestResult<TResponse>> {
    const { payload, client, endpoint, method } = config;

    return client.observeEndpoint(method, `/api/v1${endpoint}`, payload);
  }

  public static pollEndpoint<TRequest extends Record<string, unknown>, TResponse>(
    config: FactsetObserverConfig<TRequest> & { interval?: number }
  ): Observable<FactsetHighLevelRequestResult<TResponse>> {
    const { payload, client, endpoint, method, interval = 1000 } = config;

    return defer(() => from(client.requestEndpoint(method, `/api/v1${endpoint}`, payload))).pipe(
      repeat({
        delay: () => timer(interval)
      })
    );
  }
}
