import { createBroadcastSignal } from '@oms/shared-frontend/rx-broadcast';
import type { Signal } from '@oms/shared-frontend/rx-broadcast';
import { singleton, inject } from 'tsyringe';
import { ProcessState } from '@app/data-access/memory/process-id.subject';
import { NOTIFICATION_VISIBILITY } from './notifications.contracts';
import type { NotificationSignal } from './notifications.contracts';

// TODO: Extend to include error state & rename.
// By adding a new signal for error state.
/*
type NotificationSignalState = {
  visibility: NotificationVisibility;
  errorMessage?: string;
};
*/

@singleton()
export class NotificationVisiblitySignal {
  public signal: Signal<NotificationSignal>;

  // TODO: If you want a retry button, use a subect here,..... see auth.signal.ts

  constructor(@inject(ProcessState) private processState: ProcessState) {
    this.signal = createBroadcastSignal<NotificationSignal>(this.channelName, this.DEFAULT_STATE, {
      initialize$: this.processState.isLeaderProcess$,
      initializeOnce: false
    });
  }

  public get channelName() {
    return 'notifications';
  }

  public get DEFAULT_STATE(): NotificationSignal {
    return NOTIFICATION_VISIBILITY.ALL;
  }

  public reset() {
    this.signal.set(this.DEFAULT_STATE);
  }
}
