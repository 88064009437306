import { catchError, filter, interval, map, Observable, Subscription, switchMap } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import type { Disposable } from 'tsyringe';
import type { NotificationSignal, NotificationEvent } from './notifications.contracts';
import { NotificationVisiblitySignal } from './notifications.signals';
import { ApolloClientRPC } from '@app/data-access/api/apollo-client-rpc';
import { GetUserDocument } from '@oms/generated/frontend';
import type { GetUserQuery, GetUserQueryVariables } from '@oms/generated/frontend';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { allAlertsMockData } from '@app/widgets/trading/notifications/notifications.mock-data';
// import { openNewOrders, openPendingModifications, openRepairQueue } from '@app/generated/sdk';
import { AppWorkspace } from '@app/app-config/workspace.config';

const mockData$ = (userSettings: any, signal: NotificationSignal): Observable<NotificationEvent> =>
  interval(5_000).pipe(
    map((_i) => {
      const notificationEvent = {
        notification: allAlertsMockData[Math.floor(Math.random() * allAlertsMockData.length)],
        userPreferences: userSettings,
        visibility: signal,
        error: null
      };

      return notificationEvent;
    })
  );

@singleton()
export class NotificationsBackgroundService implements Disposable {
  private isInitialized = false;
  private subscription: Subscription | undefined = undefined;

  constructor(
    @inject(ApolloClientRPC) private apolloClient: ApolloClientRPC,
    @inject(NotificationVisiblitySignal) private visibilitySignal: NotificationVisiblitySignal,
    @inject(AuthService) private authService: AuthService,
    @inject(AppWorkspace) private appWorkspace: AppWorkspace
  ) {}

  initialize(): void {
    if (this.isInitialized) {
      return;
    }

    // TODO: Think about retry logic if there are errors?
    // TODO: Think about how to handle errors, and show them in the UI.
    this.apolloClient
      .watchQuery<GetUserQuery, GetUserQueryVariables>({
        query: GetUserDocument,
        variables: {
          id: this.authService.getUserId() ?? ''
        }
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          return [];
        }),
        filter((result) => !!result.data), // TODO: check
        switchMap(({ data: userPref }) =>
          this.visibilitySignal.signal.$.pipe(switchMap((signal) => mockData$(userPref, signal)))
        )
      )
      .subscribe(this.handleNotification.bind(this));

    this.isInitialized = true;
  }

  dispose(): void {
    this.subscription?.unsubscribe();
    this.isInitialized = false;
  }

  private handleNotification(_notificationEvent: NotificationEvent): void {
    /**
     * Handle notification logic:
     * Look up in dictionary of notification types
     * Apply visibility and user preferences filters
     * Pop up?
     * Play sound?
     */
    /**
     * TODO:
     * - Add visibility filter to NewIO grid
     * - Check if widget already open -> ...?
     */
    /**
    //  uncomment this block for development

    const { notification } = notificationEvent;
    switch (notification.name) {
      case 'IO New': {
        openNewOrders(this.appWorkspace.getLeaderProcessId(), {}).catch(console.error);
        break;
      }
      case 'IO Modify Request': {
        openPendingModifications(this.appWorkspace.getLeaderProcessId(), {}).catch(console.error);
        break;
      }
      case 'IO Failed':
      case 'IO Failed - Modify': {
        openRepairQueue(this.appWorkspace.getLeaderProcessId(), {}).catch(console.error);
        break;
      }
      case 'Failed Trade':
      case 'Failed Trade - Modify': {
        openRepairQueue(this.appWorkspace.getLeaderProcessId(), {}).catch(console.error);
        break;
      }
    }
      // end of block
     */
  }
}
