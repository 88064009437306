import type {
  FactsetHighLevelRequestResult,
  FactsetKeyFigure52WkRequest,
  FactsetKeyFigure52WkResponse,
  FactsetKeyFigureMnthlyRequest,
  FactsetKeyFigureMnthlyResponse,
  FactsetKeyFigureWklyRequest,
  FactsetKeyFigureWklyResponse,
  FactsetKeyFigureYtdRequest,
  FactsetKeyFigureYtdResponse,
  FactsetMapping,
  FactsetNotationStatusRequest,
  FactsetNotationStatusResponse,
  FactsetPriceRequest,
  FactsetPriceResponse,
  FactsetPricesByTypeRequest,
  FactsetPricesByTypeResponse,
  FactsetRequestResult,
  MarketDataConfig
} from './factset.types';
import { PricesByType } from './factset.types';
import type { Observable } from 'rxjs';
import { auditTime, filter, map, merge } from 'rxjs';
import type { Level1IntegrationEvent } from '@oms/generated/frontend';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import {
  marketDataErrors,
  factsetMap,
  factsetFinalizeEndpoint,
  logFactsetResponse
} from './factset.operators';
import { FactsetClient } from './factset.client';

const SUBSCRIPTION_INTERVAL = 1000;

// STUB: might need later different identifier types
const parseTicker = (ticker: string) => ticker;

// Some of Factset's data comes back as untrimmed strings so this trims those fields.
const trimAllStringValues = (e: any) => {
  Object.entries(e)
    .filter(([_, v]) => isString(v))
    .forEach(([k, v]) => {
      (e as Record<string, any>)[k] = (v as string).trim();
    });
};

const getNumericScale = (val: any): number => val?.toString()?.split('.')?.[1]?.length || 0;

const calculateMidPrice = (e: Level1IntegrationEvent) => {
  const tickSize = e.tickSize || 0;
  const bidPrice = e.bidPrice || 0;
  const askPrice = e.askPrice || 0;
  const scale = getNumericScale(tickSize || bidPrice || askPrice) || -1;
  const avgPrice = (bidPrice + askPrice) / 2;
  e.midPrice = scale > 0 ? Number(avgPrice.toFixed(scale)) : avgPrice;
};

const pricesByType = (
  config: MarketDataConfig,
  types: FactsetPricesByTypeRequest['data']['types'],
  mappings: FactsetMapping<Level1IntegrationEvent>[]
) => {
  const { client, ticker, result, logMissingFields } = config;
  const endpoint = '/prices/getByType';
  const endpoint$ = FactsetClient.observeEndpoint<FactsetPricesByTypeRequest, FactsetPricesByTypeResponse>({
    method: 'POST',
    endpoint,
    payload: {
      data: {
        identifier: parseTicker(ticker),
        identifierType: 'tickerRegion',
        types,
        quality: 'BST',
        sameQuality: true
      },
      meta: {
        subscription: {
          minimumInterval: SUBSCRIPTION_INTERVAL
        }
      }
    },
    client
  });
  return endpoint$.pipe(
    logFactsetResponse(endpoint, config.logFactsetResponse),
    auditTime(50),
    factsetMap<FactsetRequestResult<FactsetPricesByTypeResponse>, Level1IntegrationEvent>({
      logMissingFields,
      result,
      endpoint: '/prices/getByType',
      mappings
    }),
    marketDataErrors(endpoint, config),
    factsetFinalizeEndpoint({ client, endpoint, job: endpoint$ })
  );
};

const notationStatus = (config: MarketDataConfig) => {
  const { ticker, client, result, logMissingFields } = config;
  return FactsetClient.pollEndpoint<FactsetNotationStatusRequest, FactsetNotationStatusResponse>({
    interval: SUBSCRIPTION_INTERVAL,
    method: 'GET',
    endpoint: '/notation/status/get',
    payload: {
      identifier: parseTicker(ticker),
      identifierType: 'tickerRegion',
      _subscriptionMinimumInterval: SUBSCRIPTION_INTERVAL
    },
    client
  }).pipe(
    logFactsetResponse('/notation/status/get', config.logFactsetResponse),
    auditTime(50),
    factsetMap<FactsetHighLevelRequestResult<FactsetNotationStatusResponse>, Level1IntegrationEvent>({
      logMissingFields,
      result,
      endpoint: '/notation/status/get',
      mappings: [
        ['data.regional.us.caveatEmptor', 'caveatEmptor'],
        ['data.tradeImbalance', 'imbalance'],
        ['data.market.isOpen', 'isMarketOpen'],
        ['data.suspended', 'isSuspended'],
        ['data.lotSize', 'lotSize'],
        ['data.market.phase', 'marketPhase'],
        ['data.tickSize', 'tickSize'],
        ['data.tradingStatus', 'tradingStatus'],
        ['data.shortSaleRestricted', 'shortSellRestricted']
      ]
    }),
    marketDataErrors('/notation/status/get', config)
  );
};

const fiftyTwoWk = (config: MarketDataConfig) => {
  const { ticker, client, result, logMissingFields } = config;
  return FactsetClient.pollEndpoint<FactsetKeyFigure52WkRequest, FactsetKeyFigure52WkResponse>({
    interval: SUBSCRIPTION_INTERVAL,
    method: 'GET',
    endpoint: '/notation/keyFigures/year/1/get',
    payload: {
      identifier: parseTicker(ticker),
      identifierType: 'tickerRegion'
    },
    client
  }).pipe(
    logFactsetResponse('/notation/keyFigures/year/1/get', config.logFactsetResponse),
    auditTime(50),
    factsetMap<FactsetHighLevelRequestResult<FactsetKeyFigure52WkResponse>, Level1IntegrationEvent>({
      logMissingFields,
      result,
      endpoint: '/notation/keyFigures/year/1/get',
      mappings: [
        ['data.high.price', 'high52weekPrice'],
        ['data.high.date', 'high52weekDate'],
        ['data.low.price', 'low52weekPrice'],
        ['data.low.date', 'low52weekDate']
      ]
    }),
    marketDataErrors('/notation/keyFigures/year/1/get', config)
  );
};

const weekly = (config: MarketDataConfig) => {
  const { ticker, client, result, logMissingFields } = config;
  return FactsetClient.pollEndpoint<FactsetKeyFigureWklyRequest, FactsetKeyFigureWklyResponse>({
    interval: SUBSCRIPTION_INTERVAL,
    method: 'GET',
    endpoint: '/notation/keyFigures/week/1/get',
    payload: {
      identifier: parseTicker(ticker),
      identifierType: 'tickerRegion'
    },
    client
  }).pipe(
    logFactsetResponse('/notation/keyFigures/week/1/get', config.logFactsetResponse),
    auditTime(50),
    factsetMap<FactsetHighLevelRequestResult<FactsetKeyFigureWklyResponse>, Level1IntegrationEvent>({
      logMissingFields,
      result,
      endpoint: '/notation/keyFigures/week/1/get',
      mappings: [['data.tradingVolume.average', 'adv5day']]
    }),
    marketDataErrors('/notation/keyFigures/week/1/get', config)
  );
};

const monthly = (config: MarketDataConfig) => {
  const { ticker, client, result, logMissingFields } = config;
  return FactsetClient.pollEndpoint<FactsetKeyFigureMnthlyRequest, FactsetKeyFigureMnthlyResponse>({
    interval: SUBSCRIPTION_INTERVAL,
    client,
    endpoint: '/notation/keyFigures/month/1/get',
    method: 'GET',
    payload: {
      identifier: parseTicker(ticker),
      identifierType: 'tickerRegion'
    }
  }).pipe(
    logFactsetResponse('/notation/keyFigures/month/1/get', config.logFactsetResponse),
    auditTime(50),
    factsetMap<FactsetHighLevelRequestResult<FactsetKeyFigureMnthlyResponse>, Level1IntegrationEvent>({
      logMissingFields,
      result,
      endpoint: '/notation/keyFigures/month/1/get',
      mappings: [['data.tradingVolume.average', 'adv30day']]
    }),
    marketDataErrors('/notation/keyFigures/month/1/get', config)
  );
};

const prices = (config: MarketDataConfig) => {
  const { ticker, client, result, logMissingFields } = config;
  const endpoint = '/prices/get';
  const endpoint$ = FactsetClient.observeEndpoint<FactsetPriceRequest, FactsetPriceResponse>({
    method: 'GET',
    endpoint,
    payload: {
      identifier: parseTicker(ticker),
      identifierType: 'tickerRegion',
      _subscriptionMinimumInterval: SUBSCRIPTION_INTERVAL,
      quality: 'BST'
    },
    client
  });

  return endpoint$.pipe(
    logFactsetResponse('/prices/get', config.logFactsetResponse),
    auditTime(50),
    factsetMap<FactsetRequestResult<FactsetPriceResponse>, Level1IntegrationEvent>({
      logMissingFields,
      result,
      endpoint,
      mappings: [['data.accumulated.volumeWeightedAveragePrice', 'vwap']]
    }),
    marketDataErrors(endpoint, config),
    factsetFinalizeEndpoint({ client, endpoint, job: endpoint$ })
  );
};

const yearToDate = (config: MarketDataConfig) => {
  const { ticker, client, result, logMissingFields } = config;
  return FactsetClient.pollEndpoint<FactsetKeyFigureYtdRequest, FactsetKeyFigureYtdResponse>({
    interval: SUBSCRIPTION_INTERVAL,
    method: 'GET',
    endpoint: '/notation/keyFigures/yearToDate/get',
    payload: {
      identifier: parseTicker(ticker),
      identifierType: 'tickerRegion'
    },
    client
  }).pipe(
    logFactsetResponse('/notation/keyFigures/yearToDate/get', config.logFactsetResponse),
    auditTime(50),
    factsetMap<FactsetHighLevelRequestResult<FactsetKeyFigureYtdResponse>, Level1IntegrationEvent>({
      logMissingFields,
      result,
      endpoint: '/notation/keyFigures/yearToDate/get',
      mappings: [
        ['data.high.price', 'highYtdPrice'],
        ['data.high.date', 'highYtdDate'],
        ['data.low.price', 'lowYtdPrice'],
        ['data.low.date', 'lowYtdDate']
      ]
    }),
    marketDataErrors('/notation/keyFigures/yearToDate/get', config)
  );
};

/**
 * Abstracts calls required to Factset for L1 market data.
 * @param config Configuration required to get factset data
 * @returns L1 market events for the given ticker.
 */
export const level1 = (
  config: MarketDataConfig<Level1IntegrationEvent>
): Observable<Level1IntegrationEvent> => {
  config.result = config.result || {};
  return merge(
    prices(config),
    pricesByType(
      config,
      [
        // Compare with: PricesByTypes.java
        PricesByType.ASK, // index:0 / type:2
        PricesByType.AUCTION, // index:1 / type:7
        PricesByType.BID, // index:2 / type:4
        PricesByType.OFFICIAL_CLOSING_ASK, // index:3 / type:194
        PricesByType.OFFICIAL_CLOSING_BID, // index:4 / type: 193
        PricesByType.LOWER_DYNAMIC_THRESHOLD, // index:5 / type:243
        PricesByType.MID, // index:6 / type:128
        PricesByType.OFFICIAL_CLOSE, // index:7 / type:9
        PricesByType.TRADE, // index:8 / type:208 (was:1)
        PricesByType.UPPER_DYNAMIC_THRESHOLD // index:9 / type:242
      ],
      [
        // TODO: TKT-9940 will add tick direction
        // -- PricesByTypeAsk index: 0 / type: 2 --
        ['data.prices[0].latest.price', 'askPrice'],
        ['data.prices[0].latest.volume', 'askSize'],
        ['data.prices[0].latest.quoteCondition', 'askExchange'],
        // TODO: TKT-9940 will add tick direction
        ['data.prices[0].latest.tickDeleted', 'askDeleted'],
        ['data.prices[0].latest.time', 'askPriceDateTime'],
        // -- PricesByTypeAuction index: 1 / type: 7 --
        ['data.prices[1].latest.price', 'latestAuctionPrice'],
        ['data.prices[1].first.price', 'openingAuctionPrice'],
        ['data.prices[1].latest.volume', 'latestAuctionVolume'],
        ['data.prices[1].latest.time', 'latestAuctionDateTime'],
        ['data.prices[1].first.time', 'openingAuctionDateTime'],
        // -- PricesByTypeBid index: 2 / type: 4 --
        ['data.prices[2].latest.price', 'bidPrice'],
        ['data.prices[2].latest.volume', 'bidSize'],
        ['data.prices[2].latest.quoteCondition', 'bidExchange'],
        ['data.prices[2].latest.tickDeleted', 'bidDeleted'],
        // -- PricesByTypeClosingAsk index: 3 / type: 194 --
        ['data.prices[3].latest.price', 'closingAskQuotePrice'],
        ['data.prices[3].latest.volume', 'closingAskVolume'],
        // -- PricesByTypeClosingBid index: 4 / type: 193 --
        ['data.prices[4].latest.time', 'closeDateTime'], // TODO: not on backend
        ['data.prices[4].latest.price', 'closingBidQuotePrice'],
        ['data.prices[4].latest.volume', 'closingBidVolume'],
        // -- PricesByTypeLowerDynamicThreshold index: 5 / type: 243 --
        ['data.prices[5].latest.price', 'limitDownPrice'],
        // -- PricesByTypeMid index: 6 / type: 128--
        ['data.prices[6].latest.price', 'midPrice'],
        // -- PricesByTypeOfficialClose index: 7 / type: 9 --
        ['data.prices[7].latest.volume', 'closingBidVolume'], // see also: closing volume
        ['data.prices[7].latest.price', 'closingBidQuotePrice'], // see also: closing price
        ['data.prices[7].latest.time', 'closeDateTime'],
        // -- PricesByTypeTrade index: 8 / type: 1 --
        ['data.trading.shortSaleRestricted', 'shortSellRestricted'], // 'shortSaleRestricted'
        ['data.prices[8].latest.time', 'lastTradeDateTime'],
        ['data.prices[8].latest.price', 'lastTradePrice'],
        ['data.prices[8].latest.volume', 'lastTradeSize'],
        ['data.prices[8].latest.performance.intraday.absolute', 'priceChange'],
        ['data.prices[8].latest.performance.intraday.relative', 'priceChangePercent'],
        ['data.prices[8].high.price', 'highDayPrice'],
        ['data.prices[8].high.low', 'lowDayPrice'],
        ['data.prices[8].previousClose.time', 'previousCloseDateTime'],
        ['data.prices[8].previousClose.price', 'previousClosePrice'],
        ['data.prices[8].first.time', 'openDateTime'],
        ['data.prices[8].accumulated.volume', 'cumulativeVolume'],
        ['data.prices[8].valueUnit.id', 'pricingCurrency'],
        // TODO: ?
        //['data.prices[8].latest.properties', 'propertyIds'],
        // -- PricesByTypeUpperDynamicThreshold index: 9 / type: 242 --
        ['data.prices[9].latest.price', 'limitUpPrice'] // TODO: Confirm
      ]
    ),
    notationStatus(config),
    fiftyTwoWk(config),
    weekly(config),
    monthly(config),
    yearToDate(config)
  ).pipe(
    filter((e) => !isEmpty(e)),
    map((e) => {
      trimAllStringValues(e);
      // we need to prevent issues around shared object usage and calculating values stacking on top of each other.
      // i.e. priceChangePercent will store and multiply by 100 each time a new event occurs which will infinitely increase the number.
      const result = structuredClone(e);

      result.priceChangePercent = (Number(result.priceChangePercent) || 0) * 100;

      calculateMidPrice(result);
      return result;
    })
  );
};
