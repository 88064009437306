import { inject, injectable } from 'tsyringe';
import type {
  IServerSideDatasource,
  IServerSideGetRowsParams,
  RowDataUpdatedEvent
} from '@ag-grid-community/core';
import { VisibilityReason } from '@oms/generated/frontend';
import type {
  VisibleTradingOrderInfoWithAllocationsFragment,
  GetTradingOrdersQueryVariables,
  VisibleTradingOrderFilter
} from '@oms/generated/frontend';
import { RelayQueryBuilder } from '@app/common/grids/filters/filter-builders.relay';
import type { Subscription } from 'rxjs';
import { TradingOrdersSubscriptionService } from '@app/data-access/services/trading/trading-orders/trading-orders.subscriptions.service';
import { tradingOrderMonitorColumnLibrary } from './trading-order-monitor.columns';
import { merge } from 'ts-deepmerge';
import isEmpty from 'lodash/isEmpty';
import { MarketDataService } from '@app/data-access/services/marketdata/marketdata.service';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { AppWorkspace } from '@app/app-config/workspace.config';

interface CreateTradingOrderServerSideDatasourceOptions {
  filterOverrides?: VisibleTradingOrderFilter;
  filterTransform?: (filter: VisibleTradingOrderFilter) => VisibleTradingOrderFilter;
}

@injectable()
export class TradingOrderMonitorDatasourceService {
  private _marketDataService: MarketDataService;
  private _tradingOrdersSubscriptionService: TradingOrdersSubscriptionService;
  private _querySub: Subscription | undefined;
  private _relayQueryBuilder: RelayQueryBuilder<GetTradingOrdersQueryVariables>;

  constructor(
    @inject(TradingOrdersSubscriptionService) tradingOrdersDataService: TradingOrdersSubscriptionService,
    @inject(MarketDataService) marketDataService: MarketDataService,
    @inject(AppWorkspace) private workspace: AppWorkspace
  ) {
    this._tradingOrdersSubscriptionService = tradingOrdersDataService;
    this._marketDataService = marketDataService;
    this._relayQueryBuilder = RelayQueryBuilder.create<GetTradingOrdersQueryVariables>(
      tradingOrderMonitorColumnLibrary.columns
    );
  }

  public createServerSideDatasource({
    filterOverrides,
    filterTransform
  }: CreateTradingOrderServerSideDatasourceOptions = {}): IServerSideDatasource {
    return {
      getRows: (params: IServerSideGetRowsParams<VisibleTradingOrderInfoWithAllocationsFragment>) => {
        // Cancel any previous requests / subscriptions
        if (this._querySub) {
          this._querySub.unsubscribe();
        }

        // Build the query variables from the request & apply any filter overrides
        const variables = this._relayQueryBuilder.buildQuery(params.request);
        let filter = variables['filter'] || {};
        if (filterOverrides && !isEmpty(filterOverrides)) {
          filter = merge.withOptions({ mergeArrays: true }, filter, filterOverrides);
        }
        if (filterTransform) {
          filter = filterTransform(filter);
        }

        // Always append RESTRICTED OWNER to the filter
        variables.filter = this.appendRestrictedOwner(filter);

        // Create a new subscription that queries the data with filters
        // and susbcribes to updates on the data (add, update, remove)
        this._querySub = this._tradingOrdersSubscriptionService.query$(variables).subscribe((data) => {
          if (data.error) {
            console.error('Error fetching trading orders', data.error);
            params.fail();
            openMessageDialog(
              { message: data.error.message, title: 'Error fetching trading orders' },
              this.workspace
            ).catch(console.error);
            return;
          }

          const dataToUpdate =
            data.lastEvent?.type === 'update' && data.lastEvent.data?.id ? [data.lastEvent.data] : data.nodes;

          const dataToUpdateWithMarketData = dataToUpdate.map((d) => ({
            ...d,
            ...this._marketDataService.read(d.instrument?.mappings?.displayCode || '')
          }));

          // Use transactions for updates to ensure master details & grid actions are updated
          if (data.lastEvent?.type === 'update' && data.lastEvent.data?.id) {
            const rowNode = params.api.getRowNode(dataToUpdateWithMarketData[0].id);
            if (rowNode) {
              // Required to update the master details
              rowNode.setData(dataToUpdateWithMarketData[0]);
              // refreshServerSide doesn't always seem to be enough/do the trick, so we also add a transaction
              params.api.applyServerSideTransaction({
                update: dataToUpdateWithMarketData
              });
            }
          } else {
            // Othereise, just reset the data
            params.success({
              rowData: dataToUpdateWithMarketData,
              rowCount: data.totalCount
            });
          }

          // Dispatch a rowDataUpdated event to notify the grid action of the changes
          const updatedEvent: RowDataUpdatedEvent = {
            type: 'rowDataUpdated',
            api: params.api,
            context: {},
            columnApi: params.columnApi
          };
          params.api.dispatchEvent(updatedEvent);
        });
      },
      destroy: () => {
        // Clean up any subscriptions
        if (this._querySub) {
          this._querySub.unsubscribe();
        }
      }
    };
  }

  /**
   * Appends RESTRICTED OWNER. Backend needs this for the filtered search but we never display RESTRICTED OWNER
   * in the frontend.
   * @param filter Modified if the value `'OWNER'` is found and `'RESTRICTED_OWNER'` not found.
   * @private
   */
  private appendRestrictedOwner(filter: VisibleTradingOrderFilter) {
    if (filter?.and && filter.and.length > 0) {
      filter.and = filter.and.map((f) => {
        if (
          f?.reason?.in?.includes(VisibilityReason.Owner) &&
          !f?.reason?.in?.includes(VisibilityReason.RestrictedOwner)
        ) {
          f.reason.in.push(VisibilityReason.RestrictedOwner);
        }
        return f;
      });
    }

    return filter;
  }
}
