import type { UnknownRecord as _UnknownRecord } from '@oms/frontend-foundation';
import type {
  RegistryDefinitionComponent,
  RegistryDefinitionLayout,
  RegistryDefinitionDialog,
  RegistryDefinitionForm
} from '@app/app-config/registry.config';

export const OBJECT_TYPE = {
  EXAMPLE_COMPONENT: 'EXAMPLE_COMPONENT',
  EXAMPLE_LAYOUT_NODE: 'EXAMPLE_LAYOUT_NODE',
  TIME_AND_SALES: 'TIME_AND_SALES',
  CURRENCIES_GRID: 'CURRENCIES_GRID',
  EXCHANGES_GRID: 'EXCHANGES_GRID',
  ABOUT_VALSTRO: 'ABOUT_VALSTRO',
  SYSTEM_LAUNCHER: 'SYSTEM_LAUNCHER',
  SYSTEM_PLACEHOLDER: 'SYSTEM_PLACEHOLDER',
  SYSTEM_REMOTE_FORM: 'SYSTEM_REMOTE_FORM',
  SYSTEM_IMPORT_SNAPSHOT: 'SYSTEM_IMPORT_SNAPSHOT',
  SYSTEM_LAYOUTS: 'SYSTEM_LAYOUTS',
  SYSTEM_SNAPSHOTS: 'SYSTEM_SNAPSHOTS',
  EVENT_DETAILS: 'EVENT_DETAILS',
  INVESTOR_ORDER_MONITOR_GRID: 'INVESTOR_ORDER_MONITOR_GRID',
  IO_VIEW_ORDER_INFORMATION: 'IO_VIEW_ORDER_INFORMATION',
  IO_VIEW_SIDE_PANEL: 'IO_VIEW_SIDE_PANEL',
  IO_VIEW_TABBED_GRIDS: 'IO_VIEW_TABBED_GRIDS',
  IO_VIEW_TABBED_SUMMARIES: 'IO_VIEW_TABBED_SUMMARIES',
  MAPPABLE_ORDERS: 'MAPPABLE_ORDERS',
  MONTAGE_DATA_PANEL: 'MONTAGE_DATA_PANEL',
  MONTAGE_SIDE_PANEL: 'MONTAGE_SIDE_PANEL',
  NEW_ORDERS_GRID: 'NEW_ORDERS_GRID',
  NEW_ORDERS_SIDE_PANEL: 'NEW_ORDERS_SIDE_PANEL',
  NOTIFICATIONS: 'NOTIFICATIONS',
  ORDER_VWAP: 'ORDER_VWAP',
  PENDING_MODIFICATIONS_GRID: 'PENDING_MODIFICATIONS_GRID',
  PENDING_MODIFICATIONS_SIDE_PANEL: 'PENDING_MODIFICATIONS_SIDE_PANEL',
  REPAIR_QUEUE_INVESTOR_ORDERS_GRID: 'REPAIR_QUEUE_INVESTOR_ORDERS_GRID',
  REPAIR_QUEUE_TRADES_GRID: 'REPAIR_QUEUE_TRADES_GRID',
  TRADING_ORDER_MONITOR_GRID: 'TRADING_ORDER_MONITOR_GRID',
  TRADING_ORDER_MONITOR_GRID_TABLE_SERVER: 'TRADING_ORDER_MONITOR_GRID_TABLE_SERVER',
  TO_VIEW_ORDER_INFORMATION: 'TO_VIEW_ORDER_INFORMATION',
  TO_VIEW_SIDE_PANEL: 'TO_VIEW_SIDE_PANEL',
  TO_VIEW_TABBED_GRIDS: 'TO_VIEW_TABBED_GRIDS',
  TO_VIEW_TABBED_SUMMARIES: 'TO_VIEW_TABBED_SUMMARIES',
  USER_PREFERENCES_V2: 'USER_PREFERENCES_V2',
  MONTAGE_TOOLBAR: 'MONTAGE_TOOLBAR',
  STRATEGY_PARAMETERS: 'STRATEGY_PARAMETERS',
  POSITIONS_ACCOUNT_GRID: 'POSITIONS_ACCOUNT_GRID',
  POSITIONS_INSTRUMENT_GRID: 'POSITIONS_INSTRUMENT_GRID',
  CONFIRMATION: 'CONFIRMATION',
  REJECT_WITH_REASON: 'REJECT_WITH_REASON',
  ALL_FIELDS: 'ALL_FIELDS',
  EXAMPLE_LAYOUT: 'EXAMPLE_LAYOUT',
  INVESTOR_ORDER_MONITOR: 'INVESTOR_ORDER_MONITOR',
  INVESTOR_ORDER_VIEW: 'INVESTOR_ORDER_VIEW',
  MONTAGE: 'MONTAGE',
  NEW_ORDERS: 'NEW_ORDERS',
  PENDING_MODIFICATIONS: 'PENDING_MODIFICATIONS',
  REPAIR_QUEUE: 'REPAIR_QUEUE',
  TRADING_ORDER_MONITOR: 'TRADING_ORDER_MONITOR',
  TRADING_ORDER_MONITOR_TABLE_SERVER: 'TRADING_ORDER_MONITOR_TABLE_SERVER',
  TRADING_ORDER_VIEW: 'TRADING_ORDER_VIEW',
  POSITIONS: 'POSITIONS',
  ACTION_BUTTON: 'ACTION_BUTTON',
  ACTION_BUTTON_LAYOUT: 'ACTION_BUTTON_LAYOUT',
  EXAMPLE_FORM: 'EXAMPLE_FORM',
  CURRENCY_FORM: 'CURRENCY_FORM',
  EXCHANGE_FORM: 'EXCHANGE_FORM',
  SAVE_SNAPSHOT_FORM: 'SAVE_SNAPSHOT_FORM',
  CROSS_PRINCIPAL_FILL: 'CROSS_PRINCIPAL_FILL',
  INVESTOR_ORDER_ENTRY: 'INVESTOR_ORDER_ENTRY',
  PRINCIPLE_FILL_FORM: 'PRINCIPLE_FILL_FORM',
  ROUTE_ORDER: 'ROUTE_ORDER',
  TRADE_MODIFY: 'TRADE_MODIFY',
  USER_PREFERENCES_FORM: 'USER_PREFERENCES_FORM',
  REJECT_INVESTOR_ORDER: 'REJECT_INVESTOR_ORDER',
  APPLY_SETTLEMENT_FX_RATE: 'APPLY_SETTLEMENT_FX_RATE',
  CANCEL_EXECUTIONS: 'CANCEL_EXECUTIONS',
  ORDER_VWAP_FORM: 'ORDER_VWAP_FORM',
  REJECT_PENDING_MODIFICATION: 'REJECT_PENDING_MODIFICATION',
  BULK_REPAIR_TRADE: 'BULK_REPAIR_TRADE',
  ENABLE_QUOTE: 'ENABLE_QUOTE',
  MONTAGE_FILTERS_FORM: 'MONTAGE_FILTERS_FORM',
  MOCK_USER_SETTINGS: 'MOCK_USER_SETTINGS',
  MONTAGE_BUMP_PRICE: 'MONTAGE_BUMP_PRICE',
  EXAMPLE_INSTRUMENTS_WATCH_QUERY: 'EXAMPLE_INSTRUMENTS_WATCH_QUERY',
  EXAMPLE_TAGS_WATCH_QUERY: 'EXAMPLE_TAGS_WATCH_QUERY',
  EXCHANGE_COUNTRY_WATCH_QUERY: 'EXCHANGE_COUNTRY_WATCH_QUERY',
  EXCHANGE_MIC_WATCH_QUERY: 'EXCHANGE_MIC_WATCH_QUERY',
  EXCHANGE_TIMEZONE_WATCH_QUERY: 'EXCHANGE_TIMEZONE_WATCH_QUERY',
  FIRM_ACCOUNTS_WATCH_QUERY: 'FIRM_ACCOUNTS_WATCH_QUERY',
  INTERMEDIARY_ACCOUNTS_WATCH_QUERY: 'INTERMEDIARY_ACCOUNTS_WATCH_QUERY',
  VISIBLE_ACCOUNTS_WATCH_QUERY: 'VISIBLE_ACCOUNTS_WATCH_QUERY',
  VISIBLE_FIRM_ACCOUNTS_WATCH_QUERY: 'VISIBLE_FIRM_ACCOUNTS_WATCH_QUERY',
  ACTIVE_USER_WATCH_QUERY: 'ACTIVE_USER_WATCH_QUERY',
  CURRENCIES_WATCH_QUERY: 'CURRENCIES_WATCH_QUERY',
  ORDER_TAGS_WATCH_QUERY: 'ORDER_TAGS_WATCH_QUERY',
  REP_CODES_WATCH_QUERY: 'REP_CODES_WATCH_QUERY',
  LOOKUP_INSTRUMENTS_QUERY: 'LOOKUP_INSTRUMENTS_QUERY',
  LOOKUP_INSTRUMENTS_SIMPLE_QUERY: 'LOOKUP_INSTRUMENTS_SIMPLE_QUERY',
  TAGS_WATCH_QUERY: 'TAGS_WATCH_QUERY',
  TRADE_COUNTERPARTY_QUERY: 'TRADE_COUNTERPARTY_QUERY',
  WATCH_All_VENUES_FOR_USER_WITH_DESTINATION_TYPES_QUERY:
    'WATCH_All_VENUES_FOR_USER_WITH_DESTINATION_TYPES_QUERY',
  WATCH_All_VENUES_FOR_USER_QUERY: 'WATCH_All_VENUES_FOR_USER_QUERY',
  WATCH_All_ROUTABLE_USERS: 'WATCH_All_ROUTABLE_USERS'
} as const;

export type ObjectType = keyof typeof OBJECT_TYPE;

export const OBJECT_CATEGORY = {
  EXAMPLE: 'EXAMPLE',
  MARKET_DATA: 'MARKET_DATA',
  REFERENCE_DATA: 'REFERENCE_DATA',
  System: 'System',
  SYSTEM: 'SYSTEM',
  TRADING: 'TRADING'
} as const;

export type ObjectCategory = keyof typeof OBJECT_CATEGORY;

export const COMPONENT_ENUM = {
  EXAMPLE_COMPONENT: 'EXAMPLE_COMPONENT',
  EXAMPLE_LAYOUT_NODE: 'EXAMPLE_LAYOUT_NODE',
  TIME_AND_SALES: 'TIME_AND_SALES',
  CURRENCIES_GRID: 'CURRENCIES_GRID',
  EXCHANGES_GRID: 'EXCHANGES_GRID',
  ABOUT_VALSTRO: 'ABOUT_VALSTRO',
  SYSTEM_LAUNCHER: 'SYSTEM_LAUNCHER',
  SYSTEM_PLACEHOLDER: 'SYSTEM_PLACEHOLDER',
  SYSTEM_REMOTE_FORM: 'SYSTEM_REMOTE_FORM',
  SYSTEM_IMPORT_SNAPSHOT: 'SYSTEM_IMPORT_SNAPSHOT',
  SYSTEM_LAYOUTS: 'SYSTEM_LAYOUTS',
  SYSTEM_SNAPSHOTS: 'SYSTEM_SNAPSHOTS',
  EVENT_DETAILS: 'EVENT_DETAILS',
  INVESTOR_ORDER_MONITOR_GRID: 'INVESTOR_ORDER_MONITOR_GRID',
  IO_VIEW_ORDER_INFORMATION: 'IO_VIEW_ORDER_INFORMATION',
  IO_VIEW_SIDE_PANEL: 'IO_VIEW_SIDE_PANEL',
  IO_VIEW_TABBED_GRIDS: 'IO_VIEW_TABBED_GRIDS',
  IO_VIEW_TABBED_SUMMARIES: 'IO_VIEW_TABBED_SUMMARIES',
  MAPPABLE_ORDERS: 'MAPPABLE_ORDERS',
  MONTAGE_DATA_PANEL: 'MONTAGE_DATA_PANEL',
  MONTAGE_SIDE_PANEL: 'MONTAGE_SIDE_PANEL',
  NEW_ORDERS_GRID: 'NEW_ORDERS_GRID',
  NEW_ORDERS_SIDE_PANEL: 'NEW_ORDERS_SIDE_PANEL',
  NOTIFICATIONS: 'NOTIFICATIONS',
  ORDER_VWAP: 'ORDER_VWAP',
  PENDING_MODIFICATIONS_GRID: 'PENDING_MODIFICATIONS_GRID',
  PENDING_MODIFICATIONS_SIDE_PANEL: 'PENDING_MODIFICATIONS_SIDE_PANEL',
  REPAIR_QUEUE_INVESTOR_ORDERS_GRID: 'REPAIR_QUEUE_INVESTOR_ORDERS_GRID',
  REPAIR_QUEUE_TRADES_GRID: 'REPAIR_QUEUE_TRADES_GRID',
  TRADING_ORDER_MONITOR_GRID: 'TRADING_ORDER_MONITOR_GRID',
  TRADING_ORDER_MONITOR_GRID_TABLE_SERVER: 'TRADING_ORDER_MONITOR_GRID_TABLE_SERVER',
  TO_VIEW_ORDER_INFORMATION: 'TO_VIEW_ORDER_INFORMATION',
  TO_VIEW_SIDE_PANEL: 'TO_VIEW_SIDE_PANEL',
  TO_VIEW_TABBED_GRIDS: 'TO_VIEW_TABBED_GRIDS',
  TO_VIEW_TABBED_SUMMARIES: 'TO_VIEW_TABBED_SUMMARIES',
  USER_PREFERENCES_V2: 'USER_PREFERENCES_V2',
  MONTAGE_TOOLBAR: 'MONTAGE_TOOLBAR',
  STRATEGY_PARAMETERS: 'STRATEGY_PARAMETERS',
  POSITIONS_ACCOUNT_GRID: 'POSITIONS_ACCOUNT_GRID',
  POSITIONS_INSTRUMENT_GRID: 'POSITIONS_INSTRUMENT_GRID'
} as const;

export type ComponentEnumKey = keyof typeof COMPONENT_ENUM;

export type ExampleComponentDefaultComponent = {
  order?:
    | {
        intrument: {
          id: string;
          name: string;
        };
        quantity: number;
        side: unknown;
        notes: string;
        tags: {
          id: string;
          name: string;
        }[];
      }
    | undefined;
};
export type TimeAndSalesDefaultComponent = {
  instrumentId?: string | undefined;
};
export type SystemRemoteFormDefaultComponent = {
  input: {
    [x: string]: any;
  };
  schema: {
    [x: string]: any;
  };
  formId: string;
  formType?: string | undefined;
  initialValues?:
    | {
        [x: string]: any;
      }
    | undefined;
  initialFeedback?:
    | {
        code: string;
        level: 'Error' | 'Info' | 'Warning';
        message: string;
      }[]
    | undefined;
  triggerValidationOnOpen?: boolean | undefined;
  template?: string | undefined;
  templateProps?:
    | {
        [x: string]: any;
      }
    | undefined;
  formSaveType: string;
  formBuilderId: string;
};
export type EventDetailsDefaultComponent = {
  eventDetails?:
    | {
        [x: string]: unknown;
      }
    | undefined;
};
export type InvestorOrderMonitorGridDefaultComponent = {
  filters?: any;
  nonfilterable: string[];
  excluded: string[];
};
export type MappableOrdersDefaultComponent = {
  id?: string | undefined;
};
export type OrderVwapDefaultComponent = {
  /** Current status of instrument tracking set by the toggle button in the toolbar */
  trackingEnabled?: boolean | undefined;
  investorOrderId: string;
};
export type UserPreferencesV2DefaultComponent = {
  tabId?:
    | (
        | 'montage_settings'
        | 'market_data_settings'
        | 'order_settings'
        | 'notification_settings'
        | 'my_profile'
        | 'manage_layouts'
        | 'appearance'
        | 'about'
      )
    | undefined;
};

export type ComponentTypeMap = {
  [COMPONENT_ENUM.EXAMPLE_COMPONENT]: ExampleComponentDefaultComponent;
  [COMPONENT_ENUM.EXAMPLE_LAYOUT_NODE]: _UnknownRecord;
  [COMPONENT_ENUM.TIME_AND_SALES]: TimeAndSalesDefaultComponent;
  [COMPONENT_ENUM.CURRENCIES_GRID]: _UnknownRecord;
  [COMPONENT_ENUM.EXCHANGES_GRID]: _UnknownRecord;
  [COMPONENT_ENUM.ABOUT_VALSTRO]: _UnknownRecord;
  [COMPONENT_ENUM.SYSTEM_LAUNCHER]: _UnknownRecord;
  [COMPONENT_ENUM.SYSTEM_PLACEHOLDER]: _UnknownRecord;
  [COMPONENT_ENUM.SYSTEM_REMOTE_FORM]: SystemRemoteFormDefaultComponent;
  [COMPONENT_ENUM.SYSTEM_IMPORT_SNAPSHOT]: _UnknownRecord;
  [COMPONENT_ENUM.SYSTEM_LAYOUTS]: _UnknownRecord;
  [COMPONENT_ENUM.SYSTEM_SNAPSHOTS]: _UnknownRecord;
  [COMPONENT_ENUM.EVENT_DETAILS]: EventDetailsDefaultComponent;
  [COMPONENT_ENUM.INVESTOR_ORDER_MONITOR_GRID]: InvestorOrderMonitorGridDefaultComponent;
  [COMPONENT_ENUM.IO_VIEW_ORDER_INFORMATION]: _UnknownRecord;
  [COMPONENT_ENUM.IO_VIEW_SIDE_PANEL]: _UnknownRecord;
  [COMPONENT_ENUM.IO_VIEW_TABBED_GRIDS]: _UnknownRecord;
  [COMPONENT_ENUM.IO_VIEW_TABBED_SUMMARIES]: _UnknownRecord;
  [COMPONENT_ENUM.MAPPABLE_ORDERS]: MappableOrdersDefaultComponent;
  [COMPONENT_ENUM.MONTAGE_DATA_PANEL]: _UnknownRecord;
  [COMPONENT_ENUM.MONTAGE_SIDE_PANEL]: _UnknownRecord;
  [COMPONENT_ENUM.NEW_ORDERS_GRID]: _UnknownRecord;
  [COMPONENT_ENUM.NEW_ORDERS_SIDE_PANEL]: _UnknownRecord;
  [COMPONENT_ENUM.NOTIFICATIONS]: _UnknownRecord;
  [COMPONENT_ENUM.ORDER_VWAP]: OrderVwapDefaultComponent;
  [COMPONENT_ENUM.PENDING_MODIFICATIONS_GRID]: _UnknownRecord;
  [COMPONENT_ENUM.PENDING_MODIFICATIONS_SIDE_PANEL]: _UnknownRecord;
  [COMPONENT_ENUM.REPAIR_QUEUE_INVESTOR_ORDERS_GRID]: _UnknownRecord;
  [COMPONENT_ENUM.REPAIR_QUEUE_TRADES_GRID]: _UnknownRecord;
  [COMPONENT_ENUM.TRADING_ORDER_MONITOR_GRID]: _UnknownRecord;
  [COMPONENT_ENUM.TRADING_ORDER_MONITOR_GRID_TABLE_SERVER]: _UnknownRecord;
  [COMPONENT_ENUM.TO_VIEW_ORDER_INFORMATION]: _UnknownRecord;
  [COMPONENT_ENUM.TO_VIEW_SIDE_PANEL]: _UnknownRecord;
  [COMPONENT_ENUM.TO_VIEW_TABBED_GRIDS]: _UnknownRecord;
  [COMPONENT_ENUM.TO_VIEW_TABBED_SUMMARIES]: _UnknownRecord;
  [COMPONENT_ENUM.USER_PREFERENCES_V2]: UserPreferencesV2DefaultComponent;
  [COMPONENT_ENUM.MONTAGE_TOOLBAR]: _UnknownRecord;
  [COMPONENT_ENUM.STRATEGY_PARAMETERS]: _UnknownRecord;
  [COMPONENT_ENUM.POSITIONS_ACCOUNT_GRID]: _UnknownRecord;
  [COMPONENT_ENUM.POSITIONS_INSTRUMENT_GRID]: _UnknownRecord;
};

export type ComponentTypeKey = keyof ComponentTypeMap;

export const COMPONENT_DICT_ENUM = {
  EXAMPLE_COMPONENT: 'EXAMPLE_COMPONENT',
  TIME_AND_SALES: 'TIME_AND_SALES',
  CURRENCIES_GRID: 'CURRENCIES_GRID',
  EXCHANGES_GRID: 'EXCHANGES_GRID',
  ABOUT_VALSTRO: 'ABOUT_VALSTRO',
  SYSTEM_LAUNCHER: 'SYSTEM_LAUNCHER',
  SYSTEM_PLACEHOLDER: 'SYSTEM_PLACEHOLDER',
  SYSTEM_REMOTE_FORM: 'SYSTEM_REMOTE_FORM',
  SYSTEM_IMPORT_SNAPSHOT: 'SYSTEM_IMPORT_SNAPSHOT',
  SYSTEM_LAYOUTS: 'SYSTEM_LAYOUTS',
  SYSTEM_SNAPSHOTS: 'SYSTEM_SNAPSHOTS',
  EVENT_DETAILS: 'EVENT_DETAILS',
  INVESTOR_ORDER_MONITOR_GRID: 'INVESTOR_ORDER_MONITOR_GRID',
  MAPPABLE_ORDERS: 'MAPPABLE_ORDERS',
  NOTIFICATIONS: 'NOTIFICATIONS',
  ORDER_VWAP: 'ORDER_VWAP',
  USER_PREFERENCES_V2: 'USER_PREFERENCES_V2',
  STRATEGY_PARAMETERS: 'STRATEGY_PARAMETERS'
} as const;

export type ComponentDictEnumKey = keyof typeof COMPONENT_DICT_ENUM;

export type ComponentDictTypeMap = {
  [COMPONENT_ENUM.EXAMPLE_COMPONENT]: ExampleComponentDefaultComponent;
  [COMPONENT_ENUM.TIME_AND_SALES]: TimeAndSalesDefaultComponent;
  [COMPONENT_ENUM.CURRENCIES_GRID]: _UnknownRecord;
  [COMPONENT_ENUM.EXCHANGES_GRID]: _UnknownRecord;
  [COMPONENT_ENUM.ABOUT_VALSTRO]: _UnknownRecord;
  [COMPONENT_ENUM.SYSTEM_LAUNCHER]: _UnknownRecord;
  [COMPONENT_ENUM.SYSTEM_PLACEHOLDER]: _UnknownRecord;
  [COMPONENT_ENUM.SYSTEM_REMOTE_FORM]: SystemRemoteFormDefaultComponent;
  [COMPONENT_ENUM.SYSTEM_IMPORT_SNAPSHOT]: _UnknownRecord;
  [COMPONENT_ENUM.SYSTEM_LAYOUTS]: _UnknownRecord;
  [COMPONENT_ENUM.SYSTEM_SNAPSHOTS]: _UnknownRecord;
  [COMPONENT_ENUM.EVENT_DETAILS]: EventDetailsDefaultComponent;
  [COMPONENT_ENUM.INVESTOR_ORDER_MONITOR_GRID]: InvestorOrderMonitorGridDefaultComponent;
  [COMPONENT_ENUM.MAPPABLE_ORDERS]: MappableOrdersDefaultComponent;
  [COMPONENT_ENUM.NOTIFICATIONS]: _UnknownRecord;
  [COMPONENT_ENUM.ORDER_VWAP]: OrderVwapDefaultComponent;
  [COMPONENT_ENUM.USER_PREFERENCES_V2]: UserPreferencesV2DefaultComponent;
  [COMPONENT_ENUM.STRATEGY_PARAMETERS]: _UnknownRecord;
};

export type ComponentDictTypeKey = keyof ComponentDictTypeMap;

export type ComponentDictionary = {
  [key in ComponentDictEnumKey]: Omit<RegistryDefinitionComponent, 'schema'>;
};

export const COMPONENT_DICTIONARY: ComponentDictionary = {
  [COMPONENT_DICT_ENUM.EXAMPLE_COMPONENT]: {
    key: 'EXAMPLE_COMPONENT',
    title: 'Example',
    objectCategory: 'EXAMPLE',
    componentProps: {},
    showInCommandPalette: false,
    windowOptions: { width: 800, height: 400 },
    type: 'component'
  },
  [COMPONENT_DICT_ENUM.TIME_AND_SALES]: {
    key: 'TIME_AND_SALES',
    title: 'Time & Sales',
    objectCategory: 'MARKET_DATA',
    componentProps: {},
    showInCommandPalette: true,
    windowOptions: { width: 800, height: 400 },
    type: 'component'
  },
  [COMPONENT_DICT_ENUM.CURRENCIES_GRID]: {
    key: 'CURRENCIES_GRID',
    title: 'Currencies',
    objectCategory: 'REFERENCE_DATA',
    showInCommandPalette: true,
    windowOptions: { width: 800, height: 400 },
    type: 'component'
  },
  [COMPONENT_DICT_ENUM.EXCHANGES_GRID]: {
    key: 'EXCHANGES_GRID',
    title: 'Exchanges',
    objectCategory: 'REFERENCE_DATA',
    showInCommandPalette: true,
    windowOptions: { width: 800, height: 400 },
    type: 'component'
  },
  [COMPONENT_DICT_ENUM.ABOUT_VALSTRO]: {
    key: 'ABOUT_VALSTRO',
    title: 'About Valstro',
    objectCategory: 'System',
    windowOptions: { width: 440, height: 280 },
    type: 'component'
  },
  [COMPONENT_DICT_ENUM.SYSTEM_LAUNCHER]: {
    key: 'SYSTEM_LAUNCHER',
    title: 'Launcher',
    objectCategory: 'SYSTEM',
    isUnique: true,
    showInCommandPalette: false,
    windowOptions: { width: 240, height: 40 },
    type: 'component'
  },
  [COMPONENT_DICT_ENUM.SYSTEM_PLACEHOLDER]: {
    key: 'SYSTEM_PLACEHOLDER',
    title: 'Placeholder',
    objectCategory: 'SYSTEM',
    componentProps: {},
    showInCommandPalette: false,
    windowOptions: { width: 600, height: 400 },
    type: 'component'
  },
  [COMPONENT_DICT_ENUM.SYSTEM_REMOTE_FORM]: {
    key: 'SYSTEM_REMOTE_FORM',
    title: 'Form',
    objectCategory: 'SYSTEM',
    excludePropsFromSnapshot: ['schema', 'initialFeedback', 'initialValues'],
    showInCommandPalette: false,
    windowOptions: { width: 600, height: 400 },
    type: 'component'
  },
  [COMPONENT_DICT_ENUM.SYSTEM_IMPORT_SNAPSHOT]: {
    key: 'SYSTEM_IMPORT_SNAPSHOT',
    title: 'Import Layout / Snapshot',
    objectCategory: 'SYSTEM',
    showInCommandPalette: false,
    isUnique: true,
    windowOptions: { width: 600, height: 300 },
    type: 'component'
  },
  [COMPONENT_DICT_ENUM.SYSTEM_LAYOUTS]: {
    key: 'SYSTEM_LAYOUTS',
    title: 'Layouts',
    objectCategory: 'SYSTEM',
    showInCommandPalette: true,
    isUnique: true,
    windowOptions: { width: 700, height: 400 },
    type: 'component'
  },
  [COMPONENT_DICT_ENUM.SYSTEM_SNAPSHOTS]: {
    key: 'SYSTEM_SNAPSHOTS',
    title: 'Snapshots',
    objectCategory: 'SYSTEM',
    showInCommandPalette: false,
    isUnique: true,
    windowOptions: { width: 700, height: 400 },
    type: 'component'
  },
  [COMPONENT_DICT_ENUM.EVENT_DETAILS]: {
    key: 'EVENT_DETAILS',
    title: 'Event Details',
    objectCategory: 'TRADING',
    componentProps: {},
    showInCommandPalette: false,
    windowOptions: { width: 600, height: 400 },
    type: 'component'
  },
  [COMPONENT_DICT_ENUM.INVESTOR_ORDER_MONITOR_GRID]: {
    key: 'INVESTOR_ORDER_MONITOR_GRID',
    title: 'Investor Order Monitor Grid',
    objectCategory: 'TRADING',
    showInCommandPalette: false,
    exludeFromDictionary: false,
    type: 'component'
  },
  [COMPONENT_DICT_ENUM.MAPPABLE_ORDERS]: {
    key: 'MAPPABLE_ORDERS',
    title: 'Mappable Orders',
    objectCategory: 'TRADING',
    showInCommandPalette: true,
    windowOptions: { width: 700, height: 400 },
    type: 'component'
  },
  [COMPONENT_DICT_ENUM.NOTIFICATIONS]: {
    key: 'NOTIFICATIONS',
    title: 'Notification Center (Under Construction)',
    objectCategory: 'TRADING',
    componentProps: {},
    showInCommandPalette: true,
    windowOptions: { width: 400, minWidth: 350, height: 600, minHeight: 350, isMaximizable: false },
    type: 'component'
  },
  [COMPONENT_DICT_ENUM.ORDER_VWAP]: {
    key: 'ORDER_VWAP',
    title: 'Order VWAP',
    objectCategory: 'TRADING',
    componentProps: { investorOrderId: '92e84933-530e-4ca5-8043-3a561242199f', trackingEnabled: false },
    showInCommandPalette: true,
    windowOptions: { width: 400, minWidth: 300, height: 300, minHeight: 200, isMaximizable: false },
    type: 'component'
  },
  [COMPONENT_DICT_ENUM.USER_PREFERENCES_V2]: {
    key: 'USER_PREFERENCES_V2',
    title: 'User Preferences V2',
    objectCategory: 'REFERENCE_DATA',
    showInCommandPalette: true,
    exludeFromDictionary: false,
    type: 'component'
  },
  [COMPONENT_DICT_ENUM.STRATEGY_PARAMETERS]: {
    key: 'STRATEGY_PARAMETERS',
    title: 'Strategy Parameters',
    objectCategory: 'TRADING',
    windowOptions: { width: 530, height: 500 },
    type: 'component'
  }
};

export type ComponentDictionaryKey = keyof typeof COMPONENT_DICTIONARY;

export const DIALOG_ENUM = {
  CONFIRMATION: 'CONFIRMATION',
  REJECT_WITH_REASON: 'REJECT_WITH_REASON',
  ALL_FIELDS: 'ALL_FIELDS'
} as const;

export type DialogEnumKey = keyof typeof DIALOG_ENUM;

export type ConfirmationDefaultDialog = {
  message: string;
  alerts?:
    | {
        level: 'error' | 'warning' | 'info';
        messages: string | string[];
      }[]
    | undefined;
  confirmButtonText?: string | undefined;
  confirmButtonProps?:
    | {
        variant?: any;
        palette?: any;
      }
    | undefined;
  cancelButtonText?: string | undefined;
  hideCancelButton?: boolean;
  /** If true, the dialog will close automatically when the user clicks the confirm button OR the cancel button. */
  autoClose?: boolean;
};
export type RejectWithReasonDefaultDialog = {};
export type AllFieldsDefaultDialog = {
  initialValues?:
    | {
        [x: string]: any;
      }
    | undefined;
  fields?:
    | {
        [x: string]: any;
      }[]
    | undefined;
  formId: string;
  formType?: string | undefined;
};

export type DialogTypeMap = {
  [DIALOG_ENUM.CONFIRMATION]: ConfirmationDefaultDialog;
  [DIALOG_ENUM.REJECT_WITH_REASON]: RejectWithReasonDefaultDialog;
  [DIALOG_ENUM.ALL_FIELDS]: AllFieldsDefaultDialog;
};

export type DialogTypeKey = keyof DialogTypeMap;

export const DIALOG_DICT_ENUM = {
  CONFIRMATION: 'CONFIRMATION',
  REJECT_WITH_REASON: 'REJECT_WITH_REASON',
  ALL_FIELDS: 'ALL_FIELDS'
} as const;

export type DialogDictEnumKey = keyof typeof DIALOG_DICT_ENUM;

export type DialogDictTypeMap = {
  [DIALOG_ENUM.CONFIRMATION]: ConfirmationDefaultDialog;
  [DIALOG_ENUM.REJECT_WITH_REASON]: RejectWithReasonDefaultDialog;
  [DIALOG_ENUM.ALL_FIELDS]: AllFieldsDefaultDialog;
};

export type DialogDictTypeKey = keyof DialogDictTypeMap;

export type DialogDictionary = {
  [key in DialogDictEnumKey]: Omit<RegistryDefinitionDialog, 'schema'>;
};

export const DIALOG_DICTIONARY: DialogDictionary = {
  [DIALOG_DICT_ENUM.CONFIRMATION]: {
    key: 'CONFIRMATION',
    title: 'Confirmation',
    componentProps: { message: 'Are you sure?', autoClose: true, hideCancelButton: false },
    windowOptions: { width: 350, height: 250 },
    type: 'dialog'
  },
  [DIALOG_DICT_ENUM.REJECT_WITH_REASON]: {
    key: 'REJECT_WITH_REASON',
    title: 'Reject with Reason',
    componentProps: {},
    windowOptions: { width: 350, minWidth: 180, maxWidth: 700, height: 135, minHeight: 135, maxHeight: 135 },
    type: 'dialog'
  },
  [DIALOG_DICT_ENUM.ALL_FIELDS]: {
    key: 'ALL_FIELDS',
    title: 'All Fields',
    windowOptions: { width: 800, height: 600 },
    type: 'dialog'
  }
};

export type DialogDictionaryKey = keyof typeof DIALOG_DICTIONARY;

export const LAYOUT_ENUM = {
  EXAMPLE_LAYOUT: 'EXAMPLE_LAYOUT',
  INVESTOR_ORDER_MONITOR: 'INVESTOR_ORDER_MONITOR',
  INVESTOR_ORDER_VIEW: 'INVESTOR_ORDER_VIEW',
  MONTAGE: 'MONTAGE',
  NEW_ORDERS: 'NEW_ORDERS',
  PENDING_MODIFICATIONS: 'PENDING_MODIFICATIONS',
  REPAIR_QUEUE: 'REPAIR_QUEUE',
  TRADING_ORDER_MONITOR: 'TRADING_ORDER_MONITOR',
  TRADING_ORDER_MONITOR_TABLE_SERVER: 'TRADING_ORDER_MONITOR_TABLE_SERVER',
  TRADING_ORDER_VIEW: 'TRADING_ORDER_VIEW',
  POSITIONS: 'POSITIONS'
} as const;

export type LayoutEnumKey = keyof typeof LAYOUT_ENUM;

export type InvestorOrderViewDefaultLayout = {
  id: string;
};
export type MontageDefaultLayout = {
  /** Current status of instrument tracking set by the toggle button in the toolbar */
  instrumentTrackingEnabled?: boolean | undefined;
  /** Current instrument ID set by the dropdown in the toolbar */
  instrumentId?: string | undefined;
  /** Current investor account ID set by the dropdown in the toolbar */
  investorAccountId?: string | undefined;
};
export type TradingOrderViewDefaultLayout = {
  id: string;
};

export type LayoutTypeMap = {
  [LAYOUT_ENUM.EXAMPLE_LAYOUT]: _UnknownRecord;
  [LAYOUT_ENUM.INVESTOR_ORDER_MONITOR]: _UnknownRecord;
  [LAYOUT_ENUM.INVESTOR_ORDER_VIEW]: InvestorOrderViewDefaultLayout;
  [LAYOUT_ENUM.MONTAGE]: MontageDefaultLayout;
  [LAYOUT_ENUM.NEW_ORDERS]: _UnknownRecord;
  [LAYOUT_ENUM.PENDING_MODIFICATIONS]: _UnknownRecord;
  [LAYOUT_ENUM.REPAIR_QUEUE]: _UnknownRecord;
  [LAYOUT_ENUM.TRADING_ORDER_MONITOR]: _UnknownRecord;
  [LAYOUT_ENUM.TRADING_ORDER_MONITOR_TABLE_SERVER]: _UnknownRecord;
  [LAYOUT_ENUM.TRADING_ORDER_VIEW]: TradingOrderViewDefaultLayout;
  [LAYOUT_ENUM.POSITIONS]: _UnknownRecord;
};

export type LayoutTypeKey = keyof LayoutTypeMap;

export const LAYOUT_DICT_ENUM = {
  EXAMPLE_LAYOUT: 'EXAMPLE_LAYOUT',
  INVESTOR_ORDER_MONITOR: 'INVESTOR_ORDER_MONITOR',
  INVESTOR_ORDER_VIEW: 'INVESTOR_ORDER_VIEW',
  MONTAGE: 'MONTAGE',
  NEW_ORDERS: 'NEW_ORDERS',
  PENDING_MODIFICATIONS: 'PENDING_MODIFICATIONS',
  REPAIR_QUEUE: 'REPAIR_QUEUE',
  TRADING_ORDER_MONITOR: 'TRADING_ORDER_MONITOR',
  TRADING_ORDER_MONITOR_TABLE_SERVER: 'TRADING_ORDER_MONITOR_TABLE_SERVER',
  TRADING_ORDER_VIEW: 'TRADING_ORDER_VIEW',
  POSITIONS: 'POSITIONS'
} as const;

export type LayoutDictEnumKey = keyof typeof LAYOUT_DICT_ENUM;

export type LayoutDictTypeMap = {
  [LAYOUT_ENUM.EXAMPLE_LAYOUT]: _UnknownRecord;
  [LAYOUT_ENUM.INVESTOR_ORDER_MONITOR]: _UnknownRecord;
  [LAYOUT_ENUM.INVESTOR_ORDER_VIEW]: InvestorOrderViewDefaultLayout;
  [LAYOUT_ENUM.MONTAGE]: MontageDefaultLayout;
  [LAYOUT_ENUM.NEW_ORDERS]: _UnknownRecord;
  [LAYOUT_ENUM.PENDING_MODIFICATIONS]: _UnknownRecord;
  [LAYOUT_ENUM.REPAIR_QUEUE]: _UnknownRecord;
  [LAYOUT_ENUM.TRADING_ORDER_MONITOR]: _UnknownRecord;
  [LAYOUT_ENUM.TRADING_ORDER_MONITOR_TABLE_SERVER]: _UnknownRecord;
  [LAYOUT_ENUM.TRADING_ORDER_VIEW]: TradingOrderViewDefaultLayout;
  [LAYOUT_ENUM.POSITIONS]: _UnknownRecord;
};

export type LayoutDictTypeKey = keyof LayoutDictTypeMap;

export type LayoutDictionary = {
  [key in LayoutDictEnumKey]: Omit<RegistryDefinitionLayout, 'schema'>;
};

export const LAYOUT_DICTIONARY: LayoutDictionary = {
  [LAYOUT_DICT_ENUM.EXAMPLE_LAYOUT]: {
    key: 'EXAMPLE_LAYOUT',
    title: 'Example Layout',
    objectCategory: 'EXAMPLE',
    showInCommandPalette: false,
    flexLayoutActorId: 'demo-layout',
    jsonModel: {
      global: {
        tabSetHeaderHeight: -1,
        tabSetTabStripHeight: -1,
        tabEnableClose: false,
        tabEnableDrag: false,
        tabSetEnableDrag: false,
        tabSetEnableDrop: false,
        tabSetEnableMaximize: false
      },
      layout: {
        type: 'row',
        children: [
          {
            type: 'row',
            children: [
              {
                type: 'tabset',
                weight: 20,
                children: [
                  { type: 'tab', name: 'Demo Selector Widget', component: 'EXAMPLE_LAYOUT_NODE', config: {} }
                ]
              },
              {
                type: 'row',
                weight: 80,
                children: [
                  {
                    type: 'tabset',
                    weight: 50,
                    children: [
                      { type: 'tab', name: 'Demo Widget 1', component: 'EXAMPLE_LAYOUT_NODE', config: {} }
                    ]
                  },
                  {
                    type: 'tabset',
                    weight: 50,
                    children: [
                      { type: 'tab', name: 'Demo Widget 2', component: 'EXAMPLE_LAYOUT_NODE', config: {} }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    windowOptions: { width: 1100, height: 800, minWidth: 700, minHeight: 500 },
    type: 'layout'
  },
  [LAYOUT_DICT_ENUM.INVESTOR_ORDER_MONITOR]: {
    key: 'INVESTOR_ORDER_MONITOR',
    title: 'Investor Order Monitor',
    objectCategory: 'TRADING',
    exludeFromDictionary: false,
    showInCommandPalette: true,
    windowOptions: { width: 1200, height: 800 },
    jsonModel: {
      global: { tabSetHeaderHeight: 36, tabSetTabStripHeight: 28, splitterSize: 2 },
      layout: {
        type: 'row',
        children: [
          {
            type: 'tabset',
            children: [{ type: 'tab', name: 'Orders', component: 'INVESTOR_ORDER_MONITOR_GRID' }],
            active: true
          }
        ]
      }
    },
    type: 'layout'
  },
  [LAYOUT_DICT_ENUM.INVESTOR_ORDER_VIEW]: {
    key: 'INVESTOR_ORDER_VIEW',
    title: 'Investor Order View',
    objectCategory: 'TRADING',
    exludeFromDictionary: false,
    showInCommandPalette: false,
    componentProps: { id: '' },
    windowOptions: { width: 1258, height: 800 },
    jsonModel: {
      global: {
        tabSetHeaderHeight: -1,
        tabSetTabStripHeight: -1,
        tabEnableClose: false,
        tabEnableDrag: false,
        tabSetEnableDrag: false,
        tabSetEnableDrop: false,
        tabSetEnableMaximize: false,
        splitterSize: 4
      },
      layout: {
        type: 'row',
        children: [
          {
            type: 'row',
            weight: 75,
            children: [
              {
                type: 'tabset',
                weight: 17,
                children: [
                  {
                    type: 'tab',
                    name: 'Order Information',
                    component: 'IO_VIEW_ORDER_INFORMATION',
                    config: { componentProps: {} }
                  }
                ]
              },
              {
                type: 'tabset',
                weight: 33,
                children: [
                  {
                    type: 'tab',
                    name: 'Tabbed Summaries',
                    component: 'IO_VIEW_TABBED_SUMMARIES',
                    config: { componentProps: {} }
                  }
                ]
              },
              {
                type: 'tabset',
                weight: 50,
                children: [
                  {
                    type: 'tab',
                    name: 'Tabbed Grids',
                    component: 'IO_VIEW_TABBED_GRIDS',
                    config: { componentProps: {} }
                  }
                ]
              }
            ]
          },
          {
            type: 'tabset',
            weight: 25,
            children: [
              {
                type: 'tab',
                name: 'Order Side Panel',
                component: 'IO_VIEW_SIDE_PANEL',
                config: { componentProps: {} }
              }
            ]
          }
        ]
      }
    },
    type: 'layout'
  },
  [LAYOUT_DICT_ENUM.MONTAGE]: {
    key: 'MONTAGE',
    title: 'Montage',
    objectCategory: 'TRADING',
    jsonModel: {
      global: {
        rootOrientationVertical: true,
        tabSetHeaderHeight: -1,
        tabSetTabStripHeight: -1,
        splitterSize: 4,
        tabEnableClose: false,
        tabEnableDrag: false,
        tabSetEnableDrag: true,
        tabSetEnableDrop: false,
        tabSetEnableMaximize: false,
        tabSetClassNameTabStrip: 'hidden-tab-strip'
      },
      layout: {
        id: 'expanded',
        type: 'row',
        children: [
          {
            type: 'row',
            weight: 100,
            children: [
              {
                type: 'tabset',
                weight: 70,
                children: [{ type: 'tab', name: 'Data', component: 'MONTAGE_DATA_PANEL' }]
              },
              {
                type: 'tabset',
                weight: 30,
                children: [{ type: 'tab', name: 'Montage Side Panel', component: 'MONTAGE_SIDE_PANEL' }]
              }
            ]
          }
        ]
      }
    },
    windowOptions: {
      width: 1300,
      height: 700,
      minWidth: 600,
      minHeight: 740,
      isMinimizable: false,
      isMaximizable: true,
      isTitleEditable: false
    },
    type: 'layout'
  },
  [LAYOUT_DICT_ENUM.NEW_ORDERS]: {
    key: 'NEW_ORDERS',
    title: 'New Orders',
    objectCategory: 'TRADING',
    exludeFromDictionary: false,
    showInCommandPalette: true,
    isUnique: true,
    windowOptions: { width: 1235, minWidth: 1235, height: 400, minHeight: 400 },
    jsonModel: {
      global: {
        tabSetHeaderHeight: -1,
        tabSetTabStripHeight: -1,
        tabEnableClose: false,
        tabEnableDrag: false,
        tabSetEnableDrag: false,
        tabSetEnableDrop: false,
        tabSetEnableMaximize: false,
        splitterSize: 4
      },
      layout: {
        type: 'row',
        children: [
          {
            type: 'row',
            weight: 75,
            children: [
              {
                type: 'tabset',
                children: [
                  {
                    type: 'tab',
                    name: 'New orders grid',
                    component: 'NEW_ORDERS_GRID',
                    config: { componentProps: {} }
                  }
                ]
              }
            ]
          },
          {
            type: 'tabset',
            weight: 25,
            children: [
              {
                type: 'tab',
                name: 'New orders side panel',
                component: 'NEW_ORDERS_SIDE_PANEL',
                config: { componentProps: {} }
              }
            ]
          }
        ]
      }
    },
    type: 'layout'
  },
  [LAYOUT_DICT_ENUM.PENDING_MODIFICATIONS]: {
    key: 'PENDING_MODIFICATIONS',
    title: 'Pending Modifications',
    objectCategory: 'TRADING',
    exludeFromDictionary: false,
    showInCommandPalette: true,
    isUnique: true,
    windowOptions: { width: 1470, minWidth: 500, height: 500, minHeight: 500 },
    jsonModel: {
      global: {
        tabSetHeaderHeight: -1,
        tabSetTabStripHeight: -1,
        tabEnableClose: false,
        tabEnableDrag: false,
        tabSetEnableDrag: false,
        tabSetEnableDrop: false,
        tabSetEnableMaximize: false,
        splitterSize: 4
      },
      layout: {
        type: 'row',
        children: [
          {
            type: 'row',
            weight: 75,
            children: [
              {
                type: 'tabset',
                children: [
                  {
                    type: 'tab',
                    name: 'Pending modifications grid',
                    component: 'PENDING_MODIFICATIONS_GRID',
                    config: { componentProps: {} }
                  }
                ]
              }
            ]
          },
          {
            type: 'tabset',
            weight: 25,
            children: [
              {
                type: 'tab',
                name: 'Pending modifications side panel',
                component: 'PENDING_MODIFICATIONS_SIDE_PANEL',
                config: { componentProps: {} }
              }
            ]
          }
        ]
      }
    },
    type: 'layout'
  },
  [LAYOUT_DICT_ENUM.REPAIR_QUEUE]: {
    key: 'REPAIR_QUEUE',
    title: 'Repair Queue',
    objectCategory: 'TRADING',
    exludeFromDictionary: false,
    showInCommandPalette: true,
    isUnique: true,
    windowOptions: { width: 1400, minWidth: 1200, height: 600, minHeight: 400 },
    jsonModel: {
      global: {
        tabEnableClose: false,
        tabEnableDrag: false,
        tabSetEnableDrag: false,
        tabSetEnableDrop: false,
        tabSetEnableMaximize: false,
        splitterSize: 4
      },
      layout: {
        type: 'row',
        children: [
          {
            type: 'tabset',
            children: [
              { type: 'tab', name: 'Investor Orders', component: 'REPAIR_QUEUE_INVESTOR_ORDERS_GRID' },
              { type: 'tab', name: 'Trades', component: 'REPAIR_QUEUE_TRADES_GRID' }
            ],
            active: true
          }
        ]
      }
    },
    type: 'layout'
  },
  [LAYOUT_DICT_ENUM.TRADING_ORDER_MONITOR]: {
    key: 'TRADING_ORDER_MONITOR',
    title: 'Trading Order Monitor',
    objectCategory: 'TRADING',
    exludeFromDictionary: false,
    showInCommandPalette: true,
    windowOptions: { width: 1200, height: 800 },
    jsonModel: {
      global: { tabSetHeaderHeight: 36, tabSetTabStripHeight: 28 },
      layout: {
        type: 'row',
        children: [
          {
            type: 'tabset',
            children: [{ type: 'tab', name: 'Orders', component: 'TRADING_ORDER_MONITOR_GRID' }],
            active: true
          }
        ]
      }
    },
    type: 'layout'
  },
  [LAYOUT_DICT_ENUM.TRADING_ORDER_MONITOR_TABLE_SERVER]: {
    key: 'TRADING_ORDER_MONITOR_TABLE_SERVER',
    title: 'Trading Order Monitor (Table Server)',
    objectCategory: 'TRADING',
    exludeFromDictionary: false,
    showInCommandPalette: true,
    windowOptions: { width: 1200, height: 800 },
    jsonModel: {
      global: { tabSetHeaderHeight: 36, tabSetTabStripHeight: 28 },
      layout: {
        type: 'row',
        children: [
          {
            type: 'tabset',
            children: [{ type: 'tab', name: 'Orders', component: 'TRADING_ORDER_MONITOR_GRID_TABLE_SERVER' }],
            active: true
          }
        ]
      }
    },
    type: 'layout'
  },
  [LAYOUT_DICT_ENUM.TRADING_ORDER_VIEW]: {
    key: 'TRADING_ORDER_VIEW',
    title: 'Trading Order View',
    objectCategory: 'TRADING',
    exludeFromDictionary: false,
    showInCommandPalette: false,
    componentProps: { id: '' },
    windowOptions: { width: 1260, minWidth: 500, height: 800, minHeight: 500 },
    jsonModel: {
      global: {
        tabSetHeaderHeight: -1,
        tabSetTabStripHeight: -1,
        tabEnableClose: false,
        tabEnableDrag: false,
        tabSetEnableDrag: false,
        tabSetEnableDrop: false,
        tabSetEnableMaximize: false,
        splitterSize: 4
      },
      layout: {
        type: 'row',
        children: [
          {
            type: 'row',
            weight: 75,
            children: [
              {
                type: 'tabset',
                weight: 33,
                children: [
                  {
                    type: 'tab',
                    name: 'TO View Order Information',
                    component: 'TO_VIEW_ORDER_INFORMATION',
                    config: { componentProps: {} }
                  }
                ]
              },
              {
                type: 'tabset',
                weight: 33,
                children: [
                  {
                    type: 'tab',
                    name: 'TO View Tabbed Summaries',
                    component: 'TO_VIEW_TABBED_SUMMARIES',
                    config: { componentProps: {} }
                  }
                ]
              },
              {
                type: 'tabset',
                weight: 33,
                children: [
                  {
                    type: 'tab',
                    name: 'TO View Tabbed Grids',
                    component: 'TO_VIEW_TABBED_GRIDS',
                    config: { componentProps: {} }
                  }
                ]
              }
            ]
          },
          {
            type: 'tabset',
            weight: 25,
            children: [
              {
                type: 'tab',
                name: 'TO View Side Panel',
                component: 'TO_VIEW_SIDE_PANEL',
                config: { componentProps: {} }
              }
            ]
          }
        ]
      }
    },
    type: 'layout'
  },
  [LAYOUT_DICT_ENUM.POSITIONS]: {
    key: 'POSITIONS',
    title: 'Positions',
    objectCategory: 'TRADING',
    exludeFromDictionary: false,
    showInCommandPalette: true,
    windowOptions: { width: 1200, height: 800 },
    jsonModel: {
      global: { tabSetHeaderHeight: 36, tabSetTabStripHeight: 28, rootOrientationVertical: true },
      layout: {
        type: 'row',
        children: [
          {
            type: 'tabset',
            weight: 50,
            children: [{ type: 'tab', name: 'Accounts', component: 'POSITIONS_ACCOUNT_GRID' }],
            active: true
          },
          {
            type: 'tabset',
            weight: 50,
            enableTabStrip: false,
            children: [{ type: 'tab', name: 'Instruments', component: 'POSITIONS_INSTRUMENT_GRID' }]
          }
        ]
      }
    },
    type: 'layout'
  }
};

export type LayoutDictionaryKey = keyof typeof LAYOUT_DICTIONARY;

export const FORM_ENUM = {
  ACTION_BUTTON: 'ACTION_BUTTON',
  ACTION_BUTTON_LAYOUT: 'ACTION_BUTTON_LAYOUT',
  EXAMPLE_FORM: 'EXAMPLE_FORM',
  CURRENCY_FORM: 'CURRENCY_FORM',
  EXCHANGE_FORM: 'EXCHANGE_FORM',
  SAVE_SNAPSHOT_FORM: 'SAVE_SNAPSHOT_FORM',
  CROSS_PRINCIPAL_FILL: 'CROSS_PRINCIPAL_FILL',
  INVESTOR_ORDER_ENTRY: 'INVESTOR_ORDER_ENTRY',
  PRINCIPLE_FILL_FORM: 'PRINCIPLE_FILL_FORM',
  ROUTE_ORDER: 'ROUTE_ORDER',
  TRADE_MODIFY: 'TRADE_MODIFY',
  USER_PREFERENCES_FORM: 'USER_PREFERENCES_FORM',
  REJECT_INVESTOR_ORDER: 'REJECT_INVESTOR_ORDER',
  APPLY_SETTLEMENT_FX_RATE: 'APPLY_SETTLEMENT_FX_RATE',
  CANCEL_EXECUTIONS: 'CANCEL_EXECUTIONS',
  ORDER_VWAP_FORM: 'ORDER_VWAP_FORM',
  REJECT_PENDING_MODIFICATION: 'REJECT_PENDING_MODIFICATION',
  BULK_REPAIR_TRADE: 'BULK_REPAIR_TRADE',
  ENABLE_QUOTE: 'ENABLE_QUOTE',
  MONTAGE_FILTERS_FORM: 'MONTAGE_FILTERS_FORM',
  MOCK_USER_SETTINGS: 'MOCK_USER_SETTINGS',
  MONTAGE_BUMP_PRICE: 'MONTAGE_BUMP_PRICE'
} as const;

export type FormEnumKey = keyof typeof FORM_ENUM;

export type ActionButtonDefaultForm = {
  id: string;
};
export type ActionButtonLayoutDefaultForm = {
  layout: {
    requiredFields: {
      objectId: string;
      locationId: string;
      widgetTypeId: string;
    };
    allowedCommands: string[] | null;
    actions: {
      id: string;
      widgetTypeId: string;
      objectId: string;
      commandId: string;
      locationId: string;
      payload?: any;
      label: string;
      color: {
        backgroundColor: string;
        color: string;
      };
      size: string;
      order: number;
      separator: boolean;
      parentId: string | null;
    }[];
  } | null;
};
export type ExampleFormDefaultForm = {
  intrument: {
    id: string;
    name: string;
  };
  quantity: number;
  side: unknown;
  notes: string;
  tags: {
    id: string;
    name: string;
  }[];
};
export type CurrencyFormDefaultForm = {
  id: string;
};
export type ExchangeFormDefaultForm = {
  id: string;
};
export type SaveSnapshotFormDefaultForm = {
  name: string;
};
export type CrossPrincipalFillDefaultForm = {
  id: string;
};
export type InvestorOrderEntryDefaultForm = {
  entryType?: ('create' | 'update' | 'repair') | undefined;
  id?: string | undefined;
};
export type PrincipleFillFormDefaultForm = {
  ids: string[];
};
export type RouteOrderDefaultForm = {
  mode?:
    | (
        | {
            type: 'route';
            investorOrderId: string;
          }
        | {
            type: 'modify';
            tradingOrderId: string;
            location?: 'montage' | undefined;
          }
        | {
            type: 'create';
            location?: 'montage' | undefined;
          }
      )
    | undefined;
};
export type TradeModifyDefaultForm = {
  id?: string | undefined;
  entryType?: unknown | undefined;
};
export type UserPreferencesFormDefaultForm = {
  bidInitiateOrder?: boolean | undefined;
  bidSideType?: unknown | undefined;
  bidQuantityType?: unknown | undefined;
  bidQuantityValue?: (number | string) | undefined;
  bidPriceType?: unknown | undefined;
  bidTimeInForceType?:
    | {
        id?: unknown | undefined;
        subValue?: string | undefined;
      }
    | undefined;
  bidTimeInForceValue?: number | undefined;
  bidDisplaySize?: (number | string) | undefined;
  bidDestinationId?:
    | {
        id?: string | undefined;
        label?: (string | null) | undefined;
        value?: unknown | undefined;
      }
    | undefined;
  bidStrategy?:
    | {
        id?: string | undefined;
        value?: unknown | undefined;
      }
    | undefined;
  bidOrderSize?:
    | {
        id?: string | undefined;
        label?: (string | null) | undefined;
        value?: string | undefined;
      }
    | undefined;
  askInitiateOrder?: boolean | undefined;
  askSideType?: unknown | undefined;
  askQuantityType?: unknown | undefined;
  askQuantityValue?: (number | string) | undefined;
  askPriceType?: unknown | undefined;
  askTimeInForceType?:
    | {
        id?: unknown | undefined;
        subValue?: string | undefined;
      }
    | undefined;
  askTimeInForceValue?: number | undefined;
  askDisplaySize?: (number | string) | undefined;
  askDestinationId?:
    | {
        id?: string | undefined;
        label?: (string | null) | undefined;
        value?: unknown | undefined;
      }
    | undefined;
  askStrategy?:
    | {
        id?: string | undefined;
        value?: unknown | undefined;
      }
    | undefined;
  askOrderSize?:
    | {
        id?: string | undefined;
        label?: (string | null) | undefined;
        value?: string | undefined;
      }
    | undefined;
  orderMappings?: any | undefined;
  orderViewOptions?: any | undefined;
  hideOddLots?: boolean | undefined;
  displayQuotesInShares?: boolean | undefined;
  sendAttributable?: boolean | undefined;
  orderSettings?:
    | {
        id: string;
        description: string;
        rows?:
          | (
              | {
                  id: string;
                  priceFrom: number;
                  priceTo: number;
                  orderSize: number;
                }[]
              | null
            )
          | undefined;
      }[]
    | undefined;
};
export type RejectInvestorOrderDefaultForm = {
  id: string;
};
export type ApplySettlementFxRateDefaultForm = {
  orderId: string;
  executionId: string;
};
export type CancelExecutionsDefaultForm = {
  orderId: string;
  executionIds: string[];
};
export type OrderVwapFormDefaultForm = {
  investorOrderId?: string | undefined;
};
export type RejectPendingModificationDefaultForm = {
  modificationId: string;
};
export type BulkRepairTradeDefaultForm = {
  tradeRepairRequestIds: string[];
};
export type EnableQuoteDefaultForm = {
  instrument: string;
  mmStatus: boolean;
};
export type MontageFiltersFormDefaultForm = {
  instrumentTrackingEnabled?: boolean | undefined;
  instrument?: string | undefined;
  investorAccount?: string | undefined;
  layout?: string | undefined;
};
export type MockUserSettingsDefaultForm = {};
export type MontageBumpPriceDefaultForm = {};

export type FormTypeMap = {
  [FORM_ENUM.ACTION_BUTTON]: ActionButtonDefaultForm;
  [FORM_ENUM.ACTION_BUTTON_LAYOUT]: ActionButtonLayoutDefaultForm;
  [FORM_ENUM.EXAMPLE_FORM]: ExampleFormDefaultForm;
  [FORM_ENUM.CURRENCY_FORM]: CurrencyFormDefaultForm;
  [FORM_ENUM.EXCHANGE_FORM]: ExchangeFormDefaultForm;
  [FORM_ENUM.SAVE_SNAPSHOT_FORM]: SaveSnapshotFormDefaultForm;
  [FORM_ENUM.CROSS_PRINCIPAL_FILL]: CrossPrincipalFillDefaultForm;
  [FORM_ENUM.INVESTOR_ORDER_ENTRY]: InvestorOrderEntryDefaultForm;
  [FORM_ENUM.PRINCIPLE_FILL_FORM]: PrincipleFillFormDefaultForm;
  [FORM_ENUM.ROUTE_ORDER]: RouteOrderDefaultForm;
  [FORM_ENUM.TRADE_MODIFY]: TradeModifyDefaultForm;
  [FORM_ENUM.USER_PREFERENCES_FORM]: UserPreferencesFormDefaultForm;
  [FORM_ENUM.REJECT_INVESTOR_ORDER]: RejectInvestorOrderDefaultForm;
  [FORM_ENUM.APPLY_SETTLEMENT_FX_RATE]: ApplySettlementFxRateDefaultForm;
  [FORM_ENUM.CANCEL_EXECUTIONS]: CancelExecutionsDefaultForm;
  [FORM_ENUM.ORDER_VWAP_FORM]: OrderVwapFormDefaultForm;
  [FORM_ENUM.REJECT_PENDING_MODIFICATION]: RejectPendingModificationDefaultForm;
  [FORM_ENUM.BULK_REPAIR_TRADE]: BulkRepairTradeDefaultForm;
  [FORM_ENUM.ENABLE_QUOTE]: EnableQuoteDefaultForm;
  [FORM_ENUM.MONTAGE_FILTERS_FORM]: MontageFiltersFormDefaultForm;
  [FORM_ENUM.MOCK_USER_SETTINGS]: MockUserSettingsDefaultForm;
  [FORM_ENUM.MONTAGE_BUMP_PRICE]: MontageBumpPriceDefaultForm;
};

export type FormTypeKey = keyof FormTypeMap;

export const FORM_DICT_ENUM = {
  ACTION_BUTTON: 'ACTION_BUTTON',
  ACTION_BUTTON_LAYOUT: 'ACTION_BUTTON_LAYOUT',
  EXAMPLE_FORM: 'EXAMPLE_FORM',
  CURRENCY_FORM: 'CURRENCY_FORM',
  EXCHANGE_FORM: 'EXCHANGE_FORM',
  SAVE_SNAPSHOT_FORM: 'SAVE_SNAPSHOT_FORM',
  CROSS_PRINCIPAL_FILL: 'CROSS_PRINCIPAL_FILL',
  INVESTOR_ORDER_ENTRY: 'INVESTOR_ORDER_ENTRY',
  PRINCIPLE_FILL_FORM: 'PRINCIPLE_FILL_FORM',
  ROUTE_ORDER: 'ROUTE_ORDER',
  TRADE_MODIFY: 'TRADE_MODIFY',
  USER_PREFERENCES_FORM: 'USER_PREFERENCES_FORM',
  REJECT_INVESTOR_ORDER: 'REJECT_INVESTOR_ORDER',
  APPLY_SETTLEMENT_FX_RATE: 'APPLY_SETTLEMENT_FX_RATE',
  CANCEL_EXECUTIONS: 'CANCEL_EXECUTIONS',
  ORDER_VWAP_FORM: 'ORDER_VWAP_FORM',
  REJECT_PENDING_MODIFICATION: 'REJECT_PENDING_MODIFICATION',
  BULK_REPAIR_TRADE: 'BULK_REPAIR_TRADE',
  ENABLE_QUOTE: 'ENABLE_QUOTE',
  MONTAGE_FILTERS_FORM: 'MONTAGE_FILTERS_FORM',
  MOCK_USER_SETTINGS: 'MOCK_USER_SETTINGS',
  MONTAGE_BUMP_PRICE: 'MONTAGE_BUMP_PRICE'
} as const;

export type FormDictEnumKey = keyof typeof FORM_DICT_ENUM;

export type FormDictTypeMap = {
  [FORM_ENUM.ACTION_BUTTON]: ActionButtonDefaultForm;
  [FORM_ENUM.ACTION_BUTTON_LAYOUT]: ActionButtonLayoutDefaultForm;
  [FORM_ENUM.EXAMPLE_FORM]: ExampleFormDefaultForm;
  [FORM_ENUM.CURRENCY_FORM]: CurrencyFormDefaultForm;
  [FORM_ENUM.EXCHANGE_FORM]: ExchangeFormDefaultForm;
  [FORM_ENUM.SAVE_SNAPSHOT_FORM]: SaveSnapshotFormDefaultForm;
  [FORM_ENUM.CROSS_PRINCIPAL_FILL]: CrossPrincipalFillDefaultForm;
  [FORM_ENUM.INVESTOR_ORDER_ENTRY]: InvestorOrderEntryDefaultForm;
  [FORM_ENUM.PRINCIPLE_FILL_FORM]: PrincipleFillFormDefaultForm;
  [FORM_ENUM.ROUTE_ORDER]: RouteOrderDefaultForm;
  [FORM_ENUM.TRADE_MODIFY]: TradeModifyDefaultForm;
  [FORM_ENUM.USER_PREFERENCES_FORM]: UserPreferencesFormDefaultForm;
  [FORM_ENUM.REJECT_INVESTOR_ORDER]: RejectInvestorOrderDefaultForm;
  [FORM_ENUM.APPLY_SETTLEMENT_FX_RATE]: ApplySettlementFxRateDefaultForm;
  [FORM_ENUM.CANCEL_EXECUTIONS]: CancelExecutionsDefaultForm;
  [FORM_ENUM.ORDER_VWAP_FORM]: OrderVwapFormDefaultForm;
  [FORM_ENUM.REJECT_PENDING_MODIFICATION]: RejectPendingModificationDefaultForm;
  [FORM_ENUM.BULK_REPAIR_TRADE]: BulkRepairTradeDefaultForm;
  [FORM_ENUM.ENABLE_QUOTE]: EnableQuoteDefaultForm;
  [FORM_ENUM.MONTAGE_FILTERS_FORM]: MontageFiltersFormDefaultForm;
  [FORM_ENUM.MOCK_USER_SETTINGS]: MockUserSettingsDefaultForm;
  [FORM_ENUM.MONTAGE_BUMP_PRICE]: MontageBumpPriceDefaultForm;
};

export type FormDictTypeKey = keyof FormDictTypeMap;

export type FormDictionary = {
  [key in FormDictEnumKey]: Omit<RegistryDefinitionForm, 'schema'>;
};

export const FORM_DICTIONARY: FormDictionary = {
  [FORM_DICT_ENUM.ACTION_BUTTON]: {
    key: 'ACTION_BUTTON',
    title: 'Configure action',
    objectCategory: 'SYSTEM',
    form: { input: {} },
    showInCommandPalette: false,
    hasEntitlement: [],
    windowOptions: { width: 750, height: 700 },
    type: 'form'
  },
  [FORM_DICT_ENUM.ACTION_BUTTON_LAYOUT]: {
    key: 'ACTION_BUTTON_LAYOUT',
    title: 'Configure actions',
    objectCategory: 'SYSTEM',
    form: { input: {} },
    showInCommandPalette: false,
    hasEntitlement: [],
    windowOptions: { width: 600, height: 600 },
    type: 'form'
  },
  [FORM_DICT_ENUM.EXAMPLE_FORM]: {
    key: 'EXAMPLE_FORM',
    title: 'Example Form',
    objectCategory: 'EXAMPLE',
    isUnique: true,
    showInCommandPalette: false,
    windowOptions: { width: 500, height: 300 },
    type: 'form'
  },
  [FORM_DICT_ENUM.CURRENCY_FORM]: {
    key: 'CURRENCY_FORM',
    title: 'Currency',
    objectCategory: 'REFERENCE_DATA',
    form: { input: {} },
    showInCommandPalette: false,
    hasEntitlement: ['ReferenceDataView', 'ReferenceDataManage'],
    windowOptions: { width: 400, height: 240 },
    type: 'form'
  },
  [FORM_DICT_ENUM.EXCHANGE_FORM]: {
    key: 'EXCHANGE_FORM',
    title: 'Exchange',
    objectCategory: 'REFERENCE_DATA',
    form: { input: {} },
    showInCommandPalette: false,
    hasEntitlement: ['ReferenceDataView', 'ReferenceDataManage'],
    windowOptions: { width: 800, minWidth: 800, maxWidth: 800, height: 435, minHeight: 435, maxHeight: 435 },
    type: 'form'
  },
  [FORM_DICT_ENUM.SAVE_SNAPSHOT_FORM]: {
    key: 'SAVE_SNAPSHOT_FORM',
    title: 'Save Layout',
    objectCategory: 'SYSTEM',
    isUnique: true,
    form: { input: {} },
    showInCommandPalette: false,
    windowOptions: { width: 400, height: 210, isMaximizable: false, isMinimizable: false },
    type: 'form'
  },
  [FORM_DICT_ENUM.CROSS_PRINCIPAL_FILL]: {
    key: 'CROSS_PRINCIPAL_FILL',
    title: 'Cross',
    objectCategory: 'TRADING',
    form: { input: {} },
    showInCommandPalette: false,
    hasEntitlement: ['OrderManage'],
    windowOptions: {
      width: 1120,
      minWidth: 500,
      height: 522,
      minHeight: 522,
      isPinnable: true,
      isMaximizable: true,
      isMinimizable: true
    },
    type: 'form'
  },
  [FORM_DICT_ENUM.INVESTOR_ORDER_ENTRY]: {
    key: 'INVESTOR_ORDER_ENTRY',
    title: 'Investor Order Entry',
    objectCategory: 'TRADING',
    form: { input: {} },
    showInCommandPalette: true,
    hasEntitlement: ['OrderManage'],
    windowOptions: {
      width: 850,
      height: 485,
      minWidth: 850,
      minHeight: 485,
      isPinnable: true,
      isMaximizable: true,
      isMinimizable: true
    },
    type: 'form'
  },
  [FORM_DICT_ENUM.PRINCIPLE_FILL_FORM]: {
    key: 'PRINCIPLE_FILL_FORM',
    title: 'Principal Fill',
    objectCategory: 'TRADING',
    form: { input: {} },
    showInCommandPalette: false,
    hasEntitlement: ['ReferenceDataView', 'ReferenceDataManage'],
    windowOptions: { width: 800, height: 425 },
    type: 'form'
  },
  [FORM_DICT_ENUM.ROUTE_ORDER]: {
    key: 'ROUTE_ORDER',
    title: 'Trading Order Entry',
    objectCategory: 'TRADING',
    form: { input: {} },
    showInCommandPalette: true,
    hasEntitlement: ['TradeManage'],
    isUnique: true,
    windowOptions: {
      width: 820,
      height: 500,
      isPinnable: true,
      isMaximizable: false,
      minWidth: 600,
      minHeight: 300
    },
    type: 'form'
  },
  [FORM_DICT_ENUM.TRADE_MODIFY]: {
    key: 'TRADE_MODIFY',
    title: 'Trade Modify',
    objectCategory: 'TRADING',
    form: { input: {} },
    showInCommandPalette: false,
    hasEntitlement: ['TradeManage'],
    windowOptions: {
      width: 800,
      height: 300,
      minWidth: 600,
      minHeight: 200,
      isPinnable: false,
      isMaximizable: false,
      isMinimizable: true
    },
    type: 'form'
  },
  [FORM_DICT_ENUM.USER_PREFERENCES_FORM]: {
    key: 'USER_PREFERENCES_FORM',
    title: 'User Preferences',
    objectCategory: 'REFERENCE_DATA',
    form: { input: {} },
    showInCommandPalette: true,
    isUnique: true,
    hasEntitlement: ['ReferenceDataView', 'ReferenceDataManage'],
    windowOptions: { width: 800, height: 500, minWidth: 600, minHeight: 400 },
    type: 'form'
  },
  [FORM_DICT_ENUM.REJECT_INVESTOR_ORDER]: {
    key: 'REJECT_INVESTOR_ORDER',
    title: 'Reject Investor Order',
    objectCategory: 'TRADING',
    form: { input: {} },
    showInCommandPalette: false,
    windowOptions: { width: 400, height: 210, isMaximizable: false, isMinimizable: false },
    type: 'form'
  },
  [FORM_DICT_ENUM.APPLY_SETTLEMENT_FX_RATE]: {
    key: 'APPLY_SETTLEMENT_FX_RATE',
    title: 'Settlement FX Rate',
    objectCategory: 'TRADING',
    form: { input: {} },
    hasEntitlement: ['TradeManage'],
    showInCommandPalette: false,
    windowOptions: {
      width: 370,
      height: 260,
      minWidth: 370,
      minHeight: 260,
      maxWidth: 370,
      maxHeight: 260,
      isMaximizable: false,
      isMinimizable: false
    },
    type: 'form'
  },
  [FORM_DICT_ENUM.CANCEL_EXECUTIONS]: {
    key: 'CANCEL_EXECUTIONS',
    title: 'Cancel Executions',
    objectCategory: 'TRADING',
    form: { input: {} },
    hasEntitlement: ['TradeManage'],
    showInCommandPalette: false,
    windowOptions: {
      width: 550,
      height: 270,
      minWidth: 550,
      minHeight: 270,
      maxWidth: 550,
      maxHeight: 270,
      isMaximizable: false,
      isMinimizable: false
    },
    type: 'form'
  },
  [FORM_DICT_ENUM.ORDER_VWAP_FORM]: {
    key: 'ORDER_VWAP_FORM',
    title: 'Order VWAP',
    objectCategory: 'TRADING',
    form: { input: {} },
    showInCommandPalette: false,
    type: 'form'
  },
  [FORM_DICT_ENUM.REJECT_PENDING_MODIFICATION]: {
    key: 'REJECT_PENDING_MODIFICATION',
    title: 'Reject Pending Modification',
    objectCategory: 'TRADING',
    form: { input: {} },
    showInCommandPalette: false,
    windowOptions: {
      width: 400,
      height: 210,
      minWidth: 400,
      minHeight: 210,
      maxWidth: 400,
      maxHeight: 210,
      isMaximizable: false,
      isMinimizable: false
    },
    type: 'form'
  },
  [FORM_DICT_ENUM.BULK_REPAIR_TRADE]: {
    key: 'BULK_REPAIR_TRADE',
    title: 'Repair Trades',
    objectCategory: 'TRADING',
    form: { input: {} },
    showInCommandPalette: false,
    hasEntitlement: ['TradeManage'],
    isUnique: true,
    windowOptions: {
      width: 800,
      height: 275,
      isPinnable: true,
      isMaximizable: false,
      minWidth: 780,
      minHeight: 275
    },
    type: 'form'
  },
  [FORM_DICT_ENUM.ENABLE_QUOTE]: {
    key: 'ENABLE_QUOTE',
    title: 'Quote',
    objectCategory: 'TRADING',
    form: { input: {} },
    hasEntitlement: ['MontageManage'],
    showInCommandPalette: false,
    windowOptions: {
      width: 550,
      height: 215,
      minWidth: 550,
      minHeight: 215,
      maxWidth: 550,
      maxHeight: 215,
      isMaximizable: false,
      isMinimizable: false
    },
    type: 'form'
  },
  [FORM_DICT_ENUM.MONTAGE_FILTERS_FORM]: {
    key: 'MONTAGE_FILTERS_FORM',
    title: 'Montage Filters',
    objectCategory: 'TRADING',
    form: { input: {} },
    showInCommandPalette: false,
    hasEntitlement: ['MontageManage'],
    type: 'form'
  },
  [FORM_DICT_ENUM.MOCK_USER_SETTINGS]: {
    key: 'MOCK_USER_SETTINGS',
    title: 'Mock User Settings',
    objectCategory: 'REFERENCE_DATA',
    form: { input: {} },
    showInCommandPalette: false,
    exludeFromDictionary: false,
    hasEntitlement: [],
    windowOptions: {},
    type: 'form'
  },
  [FORM_DICT_ENUM.MONTAGE_BUMP_PRICE]: {
    key: 'MONTAGE_BUMP_PRICE',
    title: 'Montage Bump Price',
    objectCategory: 'TRADING',
    form: { input: {} },
    showInCommandPalette: false,
    hasEntitlement: [],
    windowOptions: {
      width: 850,
      height: 440,
      minWidth: 850,
      minHeight: 300,
      isPinnable: true,
      isMaximizable: true,
      isMinimizable: true
    },
    type: 'form'
  }
};

export type FormDictionaryKey = keyof typeof FORM_DICTIONARY;

export const ADVANCED_SELECT_QUERY_ENUM = {
  EXAMPLE_INSTRUMENTS_WATCH_QUERY: 'EXAMPLE_INSTRUMENTS_WATCH_QUERY',
  EXAMPLE_TAGS_WATCH_QUERY: 'EXAMPLE_TAGS_WATCH_QUERY',
  EXCHANGE_COUNTRY_WATCH_QUERY: 'EXCHANGE_COUNTRY_WATCH_QUERY',
  EXCHANGE_MIC_WATCH_QUERY: 'EXCHANGE_MIC_WATCH_QUERY',
  EXCHANGE_TIMEZONE_WATCH_QUERY: 'EXCHANGE_TIMEZONE_WATCH_QUERY',
  FIRM_ACCOUNTS_WATCH_QUERY: 'FIRM_ACCOUNTS_WATCH_QUERY',
  INTERMEDIARY_ACCOUNTS_WATCH_QUERY: 'INTERMEDIARY_ACCOUNTS_WATCH_QUERY',
  VISIBLE_ACCOUNTS_WATCH_QUERY: 'VISIBLE_ACCOUNTS_WATCH_QUERY',
  VISIBLE_FIRM_ACCOUNTS_WATCH_QUERY: 'VISIBLE_FIRM_ACCOUNTS_WATCH_QUERY',
  ACTIVE_USER_WATCH_QUERY: 'ACTIVE_USER_WATCH_QUERY',
  CURRENCIES_WATCH_QUERY: 'CURRENCIES_WATCH_QUERY',
  ORDER_TAGS_WATCH_QUERY: 'ORDER_TAGS_WATCH_QUERY',
  REP_CODES_WATCH_QUERY: 'REP_CODES_WATCH_QUERY',
  LOOKUP_INSTRUMENTS_QUERY: 'LOOKUP_INSTRUMENTS_QUERY',
  LOOKUP_INSTRUMENTS_SIMPLE_QUERY: 'LOOKUP_INSTRUMENTS_SIMPLE_QUERY',
  TAGS_WATCH_QUERY: 'TAGS_WATCH_QUERY',
  TRADE_COUNTERPARTY_QUERY: 'TRADE_COUNTERPARTY_QUERY',
  WATCH_All_VENUES_FOR_USER_WITH_DESTINATION_TYPES_QUERY:
    'WATCH_All_VENUES_FOR_USER_WITH_DESTINATION_TYPES_QUERY',
  WATCH_All_VENUES_FOR_USER_QUERY: 'WATCH_All_VENUES_FOR_USER_QUERY',
  WATCH_All_ROUTABLE_USERS: 'WATCH_All_ROUTABLE_USERS'
} as const;

export type AdvancedSelectQueryEnumKey = keyof typeof ADVANCED_SELECT_QUERY_ENUM;
