import { BehaviorSubject, Observable, interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { allAlertsMockData } from '@app/widgets/trading/notifications/notifications.mock-data';
import { NotificationVisibility } from '@app/notifications/notifications.contracts';
import type { SortType } from '@app/widgets/trading/notifications/notifications.widget';
import { Notification } from '@app/widgets/trading/notifications/notifications.contracts';

export class NotificationsService {
  private data$ = new BehaviorSubject<Notification[]>([allAlertsMockData[1], allAlertsMockData[2]]);

  constructor() {
    interval(5_000)
      .pipe(
        map((_i) => {
          // eslint-disable-next-line no-restricted-syntax
          console.log('Notifications: Mock notification emitted');
          const randomIndex = Math.floor(Math.random() * allAlertsMockData.length);
          const randomAlert = allAlertsMockData[randomIndex];
          const currentData = this.data$.getValue();
          this.data$.next([randomAlert, ...currentData]);
        })
      )
      .subscribe();
  }

  public watchClient$(_visibility$: Observable<NotificationVisibility>, _sort$: BehaviorSubject<SortType>) {
    return this.data$.asObservable().pipe(
      // TODO: mb implement filtering for random Notifications generator
      // withLatestFrom(visibility$, sort$),
      // eslint-disable-next-line no-console
      // tap(([notifications, vis, sort]) => console.log('Notifications: watchClient data', notifications, vis, sort)),
      map((notifications) => ({ isFetching: false, results: notifications }))
    );
  }

  public watch$() {
    return this.data$.asObservable();
  }
}
