import { v4 as UUID } from 'uuid';
import {
  FormValidationFeedbackLevel,
  Priority,
  ResourceType,
  VisibilityReason
} from '@oms/generated/frontend';
import type { Notification } from './notifications.contracts';
import { investorOrdersMockData } from './notifications.mock-investor-orders-data';

export const allAlertsMockData: Notification[] = [
  {
    id: UUID(),
    name: 'IO New',
    createdAt: '2024-09-10T15:16:51.722Z',
    priority: Priority.Top,
    addresseeType: 'AddresseeType.User',
    addressedUserId: UUID(),
    visibilityReason: VisibilityReason.UserPrimary,
    resourceFields: {
      account: 'Blackrock',
      instrument: 'MSFT-US',
      side: 'Buy',
      quantity: '1112',
      price: '89.45'
    },
    feedbackMetadata: [
      {
        code: 'ERR_OM_AccountCreditLimit_SingleOrderQuantity',
        level: FormValidationFeedbackLevel.Error,
        message: 'Exceeds Single Order Quantity Limit of 10,000 for Blackrock'
      },
      {
        code: 'ERR_OM_AccountCreditLimit_SingleOrderValue',
        level: FormValidationFeedbackLevel.Error,
        message: 'Exceeds Single Order Value Limit of 50,000 for Blackrock'
      },
      {
        code: 'WARN_OM_AccountCreditLimit_DailyOrderValue',
        level: FormValidationFeedbackLevel.Warning,
        message: 'Exceeds Daily Order Value of 1,000,000 for Blackrock'
      }
    ],
    targetObjectType: ResourceType.InvestorOrder,
    targetObjectId: UUID(),
    investorOrder: investorOrdersMockData[0] // this is of type 'IO New'. Include an IO.
  },
  {
    id: UUID(),
    name: 'IO New',
    createdAt: '2024-09-10T15:16:51.722Z',
    priority: Priority.Top,
    addresseeType: 'AddresseeType.User',
    addressedUserId: UUID(),
    visibilityReason: VisibilityReason.AccountBackup,
    resourceFields: {
      account: 'Dreyfus',
      instrument: 'AAPL-US',
      side: 'Sell',
      quantity: '11',
      price: '11.99'
    },
    feedbackMetadata: [],
    targetObjectType: ResourceType.InvestorOrder,
    targetObjectId: UUID(),
    investorOrder: investorOrdersMockData[1] // this is of type 'IO New'. Include an IO.
  },
  {
    id: UUID(),
    name: 'IO New',
    createdAt: '2024-09-10T15:16:51.722Z',
    priority: Priority.Top,
    addresseeType: 'AddresseeType.User',
    addressedUserId: UUID(),
    visibilityReason: VisibilityReason.UserTeamMember,
    resourceFields: {
      account: 'Neuberger Berman',
      instrument: 'GOOGL-US',
      side: 'Buy',
      quantity: '1001',
      price: '11.23'
    },
    feedbackMetadata: [
      {
        code: 'ERR_OM_AccountCreditLimit',
        level: FormValidationFeedbackLevel.Error,
        message: 'Exceeds Single Order Quantity Limit'
      }
    ],
    targetObjectType: ResourceType.InvestorOrder,
    targetObjectId: UUID(),
    investorOrder: investorOrdersMockData[2] // this is of type 'IO New'. Include an IO.
  },
  {
    id: UUID(),
    name: 'Failed Trade',
    createdAt: '2024-09-10T15:16:51.722Z',
    priority: Priority.Top,
    addresseeType: 'AddresseeType.User',
    addressedUserId: UUID(),
    visibilityReason: VisibilityReason.Owner,
    resourceFields: {
      account: 'Calamos Investments',
      instrument: 'T-US',
      side: 'Buy',
      quantity: '2000',
      price: '21.38'
    },
    feedbackMetadata: [
      {
        code: 'WARN_OM_CapitalThreshold',
        level: FormValidationFeedbackLevel.Warning,
        message: 'Capital threshold exceeds 50%'
      }
    ],
    targetObjectType: ResourceType.InvestorOrder,
    targetObjectId: UUID()
  },
  {
    id: UUID(),
    name: 'IO Failed',
    createdAt: '2024-09-10T15:16:51.722Z',
    priority: Priority.Top,
    addresseeType: 'AddresseeType.User',
    addressedUserId: UUID(),
    visibilityReason: VisibilityReason.RestrictedOwner,
    resourceFields: {
      account: 'FRANKLIN',
      instrument: 'YUM-US',
      side: 'Buy',
      quantity: '300',
      price: '140.09'
    },
    feedbackMetadata: [],
    targetObjectType: ResourceType.InvestorOrder,
    targetObjectId: UUID()
  },
  {
    id: UUID(),
    name: 'IO Failed',
    createdAt: '2024-09-10T15:16:51.722Z',
    priority: Priority.Top,
    addresseeType: 'AddresseeType.User',
    addressedUserId: UUID(),
    visibilityReason: VisibilityReason.AccountPrimary,
    resourceFields: {
      account: 'RENTECH',
      instrument: 'CAKE-US',
      side: 'Buy',
      quantity: '4000',
      price: '42.64'
    },
    feedbackMetadata: [],
    targetObjectType: ResourceType.InvestorOrder,
    targetObjectId: UUID()
  },
  {
    id: UUID(),
    name: 'IO Cancel Request',
    createdAt: '2024-09-10T15:16:51.722Z',
    priority: Priority.Top,
    addresseeType: 'AddresseeType.User',
    addressedUserId: UUID(),
    visibilityReason: VisibilityReason.RestrictedBackup,
    resourceFields: {
      account: 'TROWE',
      instrument: 'POST-US',
      side: 'Buy',
      quantity: '500',
      price: '114.39'
    },
    feedbackMetadata: [],
    targetObjectType: ResourceType.InvestorOrder,
    targetObjectId: UUID()
  },
  {
    id: UUID(),
    name: 'IO Modify Request',
    createdAt: '2024-09-10T15:16:51.722Z',
    priority: Priority.Top,
    addresseeType: 'AddresseeType.User',
    addressedUserId: UUID(),
    visibilityReason: VisibilityReason.RestrictedPrimary,
    resourceFields: {
      account: 'Fidelity Investments',
      instrument: 'SPY-US',
      side: 'Buy',
      quantity: '6000',
      price: '610.29'
    },
    feedbackMetadata: [],
    targetObjectType: ResourceType.InvestorOrder,
    targetObjectId: UUID()
  },
  {
    id: UUID(),
    name: 'Capital Threshold Warning',
    createdAt: '2024-09-10T15:16:51.722Z',
    priority: Priority.Top,
    addresseeType: 'AddresseeType.User',
    addressedUserId: UUID(),
    visibilityReason: VisibilityReason.RestrictedPrimary,
    resourceFields: {
      account: 'Fidelity Investments',
      instrument: 'GE-US',
      side: 'Sell',
      quantity: '200',
      price: '192.33'
    },
    feedbackMetadata: [],
    targetObjectType: ResourceType.InvestorOrder,
    targetObjectId: UUID()
  },
  {
    id: UUID(),
    name: 'IO Failed',
    createdAt: '2024-09-10T15:16:51.722Z',
    priority: Priority.Top,
    addresseeType: 'AddresseeType.User',
    addressedUserId: UUID(),
    visibilityReason: VisibilityReason.RestrictedPrimary,
    resourceFields: {
      account: 'TROWE',
      instrument: 'TSLA-US',
      side: 'Sell',
      quantity: '3000',
      price: '228.83'
    },
    feedbackMetadata: [],
    targetObjectType: ResourceType.InvestorOrder,
    targetObjectId: UUID()
  },
  {
    id: UUID(),
    name: 'IO Failed - Modify',
    createdAt: '2024-09-10T15:16:51.722Z',
    priority: Priority.Top,
    addresseeType: 'AddresseeType.User',
    addressedUserId: UUID(),
    visibilityReason: VisibilityReason.RestrictedPrimary,
    resourceFields: {
      account: 'RENTECH',
      instrument: 'T-US',
      side: 'Sell',
      quantity: '400',
      price: '21.38'
    },
    feedbackMetadata: [],
    targetObjectType: ResourceType.InvestorOrder,
    targetObjectId: UUID()
  },
  {
    id: UUID(),
    name: 'Failed Trade',
    createdAt: '2024-09-10T15:16:51.722Z',
    priority: Priority.Top,
    addresseeType: 'AddresseeType.User',
    addressedUserId: UUID(),
    visibilityReason: VisibilityReason.RestrictedPrimary,
    resourceFields: {
      account: 'FRANKLIN',
      instrument: 'YUM-US',
      side: 'Sell',
      quantity: '5000',
      price: '140.09'
    },
    feedbackMetadata: [],
    targetObjectType: ResourceType.InvestorOrder,
    targetObjectId: UUID()
  },
  {
    id: UUID(),
    name: 'Failed Trade - Modify',
    createdAt: '2024-09-10T15:16:51.722Z',
    priority: Priority.Top,
    addresseeType: 'AddresseeType.User',
    addressedUserId: UUID(),
    visibilityReason: VisibilityReason.RestrictedPrimary,
    resourceFields: {
      account: 'Calamos Investments',
      instrument: 'POST-US',
      side: 'Sell',
      quantity: '600',
      price: '114.39'
    },
    feedbackMetadata: [],
    targetObjectType: ResourceType.InvestorOrder,
    targetObjectId: UUID()
  }
];
